import { Input } from "baseui/input";
import React, { Component } from "react";
import Button from "../../elements/Button";
import { me, get as getSubscriptionPlan } from "../../api/subscription-plan";
import { me as myBankAccounts } from "../../api/bank-account";
import { withRouter } from "react-router-dom";
import { withContext } from "../../context/Auth";
import * as Theme from "../../util/theme";
import { formatDate } from "../../util/datetime";
import MoonLoader from "react-spinners/MoonLoader";
import EmptyState from "../../elements/EmptyState";
import LoaderBlock from "../loaders/LoaderBlock";

export default class Subscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // client: {},
      loading: true,
      subscription: {},
      bankAccounts: [],
    };
  }

  componentDidMount() {
    this.getMySubscription();
    this.getBankAccounts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.client?._id != this.props?.client?._id) {
      this.setState({ loading: true });
      return this.getMySubscription();
    }
  }

  async getMySubscription() {
    const { client } = this.props;

    try {
      const { data: subscription } = await me.get(client._id);
      console.log(subscription);

      if (!subscription) return this.setState({ subscription: {}, subscriptionPlan: null, loading: false });

      const { data: subscriptionPlan } = await getSubscriptionPlan(subscription.plan_id);
      this.setState({ subscription, subscriptionPlan });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  async getBankAccounts() {
    const { client } = this.props;

    try {
      const { data: bankAccounts } = await myBankAccounts.get(client._id);
      console.log(bankAccounts);
      if (!bankAccounts) return;

      this.setState({ bankAccounts });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading, subscription, subscriptionPlan, bankAccounts } = this.state;

    if (loading) {
      return LoaderState;
    }

    // If not loading && there is no bank accounts, PROMPT USER TO ADD BANK ACCOUNT
    if (!loading && bankAccounts.length == 0) {
      // return LoaderState;
      return (
        <div class='flex justify-center mt-8'>
          <EmptyState heading='' text='' illustration='creditcard' buttonText='Add Bank Account' onClick={() => this.props.toForm("bank-account")} />
        </div>
      );
    }

    // If not loading && there is no subscription, PROMPT USER TO SUBSCRIBE
    if (!loading && subscription?._id == null) {
      // return LoaderState;
      return (
        <div class='flex justify-center mt-8'>
          <EmptyState heading='' text='' illustration='document' buttonText='Manage Subscription' onClick={() => this.props.toForm("subscription")} />
        </div>
      );
    }

    return (
      <div >
        <div class='relative'>
          <div class='flex justify-between items-end flex-row flex-1 mt-8'>
            <p class='font-lg font-bold' style={{ color: "rgb(140,140,140)" }}>
              Subscription
            </p>
            <>
              <Button text='View Subscriptions' type='tertiary' onClick={() => this.props.toForm("subscription")} />
            </>
          </div>
        </div>

        {subscription._id && (
          <div class='card mt-4 p-8 max-w-none' style={{ position: "relative" }}>
            <p class='text-xl font-bold mb-4'>{subscriptionPlan?.name}</p>
            <div class='flex flex-1 flex-row mb-8'>
              {/* <div class='flex-1'> */}
              {/* <p class='label'>Fund Name</p> */}
              {/* {this.inputFactory("g_na_country")} */}
              {/* <p class='value'>{subscriptionPlan.name}</p> */}
              {/* </div> */}
              <div class='flex-1'>
                <p class='label'>Amount</p>
                {/* {this.inputFactory("g_na_city")} */}
                <p class='value'>SAR {subscription?.amount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
              </div>
            </div>

            <div class='flex flex-1 flex-row '>
              <div class='flex-1'>
                <p class='label'>Date Subscribed</p>
                <p class='value'>{formatDate(subscription.created_at)}</p>
                {/* {this.inputFactory("Riyadh", "bank")} */}
              </div>
              <div class='flex-1'>
                {/* <p class='label'>Street</p> */}
                {/* {this.inputFactory("123 Abc. St", "date_of_opening")} */}
              </div>
            </div>

            <div style={{ position: "absolute", right: 16, bottom: 12, cursor: "grab" }} onClick={this.toggleEdit}>
              {/* <h1 class='faded text-xs' style={{ textDecoration: "underline" }}>
              Edit Profile
            </h1> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const LoaderState = (
  <div>
    <div class='relative'>
      <div class='flex justify-between items-end flex-row flex-1 mt-8'>
        <p class='font-lg font-bold' style={{ color: "rgb(140,140,140)" }}>
          <LoaderBlock height={32} width={200} marginBottom={8} />
        </p>
        <LoaderBlock height={40} width={200} marginBottom={8} />
      </div>
    </div>

    <div class='card mt-4 p-8' style={{ position: "relative" }}>
      <LoaderBlock height={32} width={200} marginBottom={16} />
      <div class='flex flex-1 flex-row mb-8'>
        <div class='flex-1'>
          {/* <p class='label'>Fund Name</p> */}
          {/* {this.inputFactory("g_na_country")} */}
          {/* <p class='value'>{subscriptionPlan.name}</p> */}
          <LoaderBlock height={12} width={80} marginBottom={8} />
          <LoaderBlock height={16} width={140} marginBottom={8} />
        </div>
        <div class='flex-1'>
          {/* {this.inputFactory("g_na_city")} */}
          <LoaderBlock height={12} width={80} marginBottom={8} />
          <LoaderBlock height={16} width={140} marginBottom={8} />
          {/* <p class='value'>${subscription?.amount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> */}
        </div>
      </div>

      <div class='flex flex-1 flex-row '>
        <div class='flex-1'>
          <LoaderBlock height={12} width={80} marginBottom={8} />
          <LoaderBlock height={16} width={140} marginBottom={8} />
          {/* {this.inputFactory("Riyadh", "bank")} */}
        </div>
        <div class='flex-1'>
          <LoaderBlock height={12} width={80} marginBottom={8} />
          <LoaderBlock height={16} width={140} marginBottom={8} />
          {/* <p class='label'>Street</p> */}
          {/* {this.inputFactory("123 Abc. St", "date_of_opening")} */}
        </div>
      </div>

      <div style={{ position: "absolute", right: 16, bottom: 12, cursor: "grab" }}>
        {/* <h1 class='faded text-xs' style={{ textDecoration: "underline" }}>
              Edit Profile
            </h1> */}
      </div>
    </div>
  </div>
);
