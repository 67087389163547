import * as React from "react"
import { withStyle, useStyletron } from "baseui"
import { Block } from "baseui/block"
import ArrowUp from "baseui/icon/arrow-up"
import ArrowDown from "baseui/icon/arrow-down"
import Search from "baseui/icon/search"
import Plus from "baseui/icon/plus"
import Delete from "baseui/icon/delete"
import Overflow from "baseui/icon/overflow"
import { StyledTable, StyledHead, StyledHeadCell, StyledBody, StyledRow, StyledCell, StyledAction } from "baseui/table"
import { ParagraphXSmall, LabelXSmall, ParagraphSmall, LabelLarge } from "baseui/typography"

import { find } from "../../api/client"
import { withContext } from "../../context/Auth"
import Button from "../../elements/Button"

const ShortHeaderCell = withStyle(StyledHead, (props) => ({
  width: "100px",
}))

const ShortCell = withStyle(StyledCell, (props) => ({
  width: "100px",
}))

const StyledFormCell = withStyle(StyledCell, (props) => ({
  ...props.$theme.typography.font650,
  border: "0px",
  // padding: 0,
  // borderBottom: '2px solid',
  // borderColor: props.$progress < 75
  // ? props.$theme.colors.negative200
  // : props.$progress < 98 ? props.$theme.colors.warning200 : props.$theme.colors.positive200,
  alignItems: "center",
  backgroundColor: props.$progress < 75 ? props.$theme.colors.negative100 : props.$progress < 98 ? props.$theme.colors.warning100 : props.$theme.colors.positive100,
  color: props.$progress < 75 ? props.$theme.colors.negative : props.$progress < 98 ? props.$theme.colors.warning500 : props.$theme.colors.positive,
}))

const UITable = (props) => {
  const [css] = useStyletron()
  const [clients, setClients] = React.useState([])
  const [isFetching, setIsFetching] = React.useState(false)

  async function getClients() {
    if (isFetching) return
    setIsFetching(true)
    if (clients.length > 0) return
    let { data: c } = await find({ company_id: props.context.user?.company_id, withProgress: true })
    console.log(c)
    setClients(c)
  }
  getClients()

  return (
    <div className={css({ height: "100%" })}>
      <StyledTable>
        <StyledHead>
          <StyledHeadCell>Name</StyledHeadCell>
          <StyledHeadCell>KYC Progress</StyledHeadCell>
          <StyledHeadCell>KYC ✅</StyledHeadCell>
          <StyledHeadCell>FATCA Progress</StyledHeadCell>
          <StyledHeadCell>FATCA ✅</StyledHeadCell>
          <StyledHeadCell>Remind</StyledHeadCell>
        </StyledHead>
        <StyledBody>
          {clients.map((row, index) => (
            <StyledRow key={index}>
              <StyledCell className='cursor:pointer cursor-pointer' onClick={() => window.open(window.location.hostname + '/dashboard/' + row._id)}>{row.name}</StyledCell>
              <StyledFormCell $progress={row.kyc_progress}>{row.kyc_progress.toFixed(2)}%</StyledFormCell>
              <StyledCell>{row.kyc_approved ? "✅" : "❌"}</StyledCell>
              {/* <StyledHeadingCell>
                <Block>
                <LabelLarge>{row.kyc_completion}%</LabelLarge>
                <ParagraphSmall as="div">{row[1]}</ParagraphSmall>
                </Block>
              </StyledHeadingCell> */}
              <StyledFormCell $progress={row.fatca_progress}>
                {row.fatca_progress.toFixed(2)}%
                {/* <React.Fragment>
                  {row[3] < 0 ? (
                    <ArrowDown size={24} />
                    ) : (
                      <ArrowUp size={24} />
                      )}
                      {row[3]}%
                    </React.Fragment> */}
              </StyledFormCell>
              <StyledCell>{row.fatca_approved ? "✅" : "❌"}</StyledCell>

              <StyledCell style={{ alignItems: "flex-end", justifyContent: "center" }}>
                {row.kyc_progress >= 99 && row.fatca_progress >= 99 ? <p className="text-center text-lg">✅</p> : <Button text="Remind" type="dark" onClick={() => props.onRemindClient(row)} />}
                {/* <StyledAction>
                  <Search />
                </StyledAction>
                <StyledAction>
                  <Plus />
                </StyledAction>
                <StyledAction>
                  <Delete />
                </StyledAction>
                <StyledAction>
                  <Overflow />
                </StyledAction> */}
              </StyledCell>
            </StyledRow>
          ))}
        </StyledBody>
      </StyledTable>
    </div>
  )
}

export default withContext(UITable)
