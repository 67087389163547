import React, { Component } from "react";
import { MoonLoader } from "react-spinners";
import { Input } from "baseui/input";
import * as Theme from "../util/theme";
import { withRouter } from "react-router-dom";
import logo from "../data/images/i46logo.png";

import { getResetPassword, resetPassword } from "../api/auth";
import Button from "../elements/Button";

class ResetPassword extends Component {
  state = {
    password: "",
    resetPassword: null,
    error: null,
    loading: false,
  };

  componentDidMount() {
    // this.getPasswordReset();
  }

  async getPasswordReset() {
    const { resetId } = this.props;

    try {
      const { data: resetPassword } = await getResetPassword(resetId);
      if (!resetPassword) {
        return this.setState({ error: "This reset password link is invalid" });
      }
      console.log(resetPassword);
      return this.setState({ resetPassword });
    } catch (e) {
      console.log(e);
    }
  }

  onSubmit = async () => {
    this.setState({ loading: true });

    const { resetId } = this.props;
    const { password } = this.state;

    try {
      await resetPassword(resetId, password);

      window.location.pathname = '/login'
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading, error } = this.state;

    return (
      <div class='min-h-screen p-8 pt-64 lg:p-64' style={{ backgroundColor: Theme.PRIMARY }}>
        <div style={{ position: "absolute", left: 16, top: 16 }}>
          <img src={logo} width='100' alt='logo.png' />
        </div>
        <p class='text-5xl font-bold mb-4' style={{ color: "white", opacity: 0.7 }}>
          Forgot Password
        </p>
        <div class='flex card flex-col pb-8'>
          <div class='mb-4'>
            <p class='label mb-2'>Password</p>
            <Input onChange={(e) => this.setState({ password: e.target.value, error: null })} placeholder='Password' type='password' />
          </div>

          <div class='relative flex flex-row items-center mt-4'>
            <div style={{position: 'absolute', right: 0, paddingBlock: 8}}>
              <Button text='Reset Password' class='submit flex-1 faded mr-4' onClick={this.onSubmit} disabled={error != null} type='tertiary' />
            </div>
            {loading && <MoonLoader size='32' width='40px' color={Theme.PRIMARY} />}
          </div>
          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPassword);
