import "./App.css";
import SectionManager from "./components/SectionManager";
import Dashboard from "./components/Dashboard";
import InviteClient from "./components/InviteClient";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import ViewSubscriptions from "./components/Subscription/ViewSubscriptions";
import BankAccountForm from "./components/BankAccount/BankAccountForm";
import FATCAForm from "./components/FATCA/FATCA";
import AMLForm from "./components/AML/AML";
import Onboard from "./components/Onboard/Onboard";
import OnboardPasswordOnly from "./components/Onboard/OnboardPasswordOnly";
import UserProvider from "./context/Auth";
import MasterView from "./components/MasterView/MasterView";

import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import "tailwindcss/tailwind.css";

function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <Switch>

          
          <Route path='/onboard-u/:inviteId' render={({ match }) => <OnboardPasswordOnly inviteId={match.params.inviteId} />} />
          <Route path='/onboard/:inviteId' render={({ match }) => <Onboard inviteId={match.params.inviteId} />} />

          <Route path='/login' render={() => <Login />} />
          
          <Route exact path='/reset-password/:resetId' render={({ match }) => <ResetPassword resetId={match.params.resetId} />} />

          <Route exact path='/master' render={({ match }) => <MasterView />}/>

          <Route path='/invite'>
            <InviteClient />
          </Route>

          <Route path='/dashboard/:clientId' render={({ match }) => <Dashboard clientId={match.params.clientId} />} />
          <Route path='/dashboard' render={({ match }) => <Dashboard clientId={null} />} />

          <Route path='/form/:clientId' render={({ match }) => <SectionManager clientId={match.params.clientId} />} />

          <Route path='/subscription/:clientId' render={({ match }) => <ViewSubscriptions clientId={match.params.clientId} />} />
          
          <Route path='/bank-account/:clientId' render={({ match }) => <BankAccountForm clientId={match.params.clientId} />} />
          
          <Route path='/fatca/:clientId' render={({ match }) => <FATCAForm clientId={match.params.clientId} />} />
          
          <Route path='/aml/:clientId' render={({ match }) => <AMLForm clientId={match.params.clientId} />} />

          <Route path='/'>
            <Redirect to='/login' />
          </Route>
        
        </Switch>
      </BrowserRouter>
    </UserProvider>
  );

}

export default App;
