export function getFieldIndexes(id, form) {
  for (let i = 0; i < form.data.sections.length; i++) {
    for (let j = 0; j < form.data.sections[i].subsections.length; j++) {
      for (let k = 0; k < form.data.sections[i].subsections[j].fields.length; k++) {
        if (form.data.sections[i].subsections[j].fields[k].id == id) {
          return [i, j, k];
        }
      }
    }
  }
  return null;
}

export function getFieldIndexesByTitle(title, form) {
  for (let i = 0; i < form.data.sections.length; i++) {
    for (let j = 0; j < form.data.sections[i].subsections.length; j++) {
      for (let k = 0; k < form.data.sections[i].subsections[j].fields.length; k++) {
        if (form.data.sections[i].subsections[j].fields[k].title == title) {
          return [i, j, k];
        }
      }
    }
  }
  return null;
}

export function getProgress(form, sectionIndex) {

  if (!form || form._id == null) return;

  let total = 0;
  let completed = 0;
  // for (let i = 0; i < form.data.sections.length; i++) {
  for (let j = 0; j < form.data.sections[sectionIndex].subsections.length; j++) {
    for (let k = 0; k < form.data.sections[sectionIndex].subsections[j].fields.length; k++) {
      if(form.data.sections[sectionIndex].subsections[j].fields[k].conditionRef != null && form.data.sections[sectionIndex].subsections[j].fields[k].conditionRef != "") {
        // NOT NECESSARILY if it has a condition, then count it
        // total++

        const indexes = getFieldIndexes(form.data.sections[sectionIndex].subsections[j].fields[k].conditionRef, form)
        const conditionalField = form.data.sections[indexes[0]].subsections[indexes[1]].fields[indexes[2]]
        // if it has a condition, see if that condition is met

        if(conditionalField.value == form.data.sections[sectionIndex].subsections[j].fields[k].condition) {
          total++;
          if(form.data.sections[sectionIndex].subsections[j].fields[k].value != null && form.data.sections[sectionIndex].subsections[j].fields[k].value != "") {
            completed++
            continue;
          }
        }

        // if the conditional field has a value then count it
        // if(conditionalField.value != null && conditionalField.value != "") {
        //   completed++;
        // }
        // continue;
      }

      total++;
      if (form.data.sections[sectionIndex].subsections[j].fields[k].value != null && form.data.sections[sectionIndex].subsections[j].fields[k].value != "") {
        completed++;
      }
    }
  }
  // }
  return ((completed / total) * 100).toFixed(0);
}

/**
 * Get overall progress of an entire form for the overall progress bar
 */
 export function getOverallProgress(form) {
  if (!form || form._id == null) return;

  let total = 0;
  let completed = 0;
  for (let i = 0; i < form.data.sections.length; i++) {
    for (let j = 0; j < form.data.sections[i]?.subsections?.length; j++) {
      for (let k = 0; k < form.data.sections[i]?.subsections[j]?.fields?.length; k++) {
        if (form.data.sections[i].subsections[j].fields[k].conditionRef != null && form.data.sections[i].subsections[j].fields[k].conditionRef != "") {
          const indexes = getFieldIndexes(form.data.sections[i].subsections[j].fields[k].conditionRef, form);
          const conditionalField = form.data.sections[indexes[0]].subsections[indexes[1]].fields[indexes[2]];

          // if it has a condition, see if that condition is met
          if (conditionalField.value == form.data.sections[i].subsections[j].fields[k].condition) {
            total++;
            if (form.data.sections[i].subsections[j].fields[k].value != null && form.data.sections[i].subsections[j].fields[k].value != "") {
              completed++;
              continue;
            }
          }

          // if the conditional field has a value then count it
          // if(conditionalField.value != null && conditionalField.value != "") {
          //   completed++;
          // }
          continue;
        }
        total++;
        if (form.data.sections[i]?.subsections[j]?.fields[k]?.value != null && form.data.sections[i]?.subsections[j]?.fields[k]?.value != "") {
          completed++;
        }
      }
    }
  }
  console.log('TOTAL: ', total)
  console.log('COMPLETED: ', completed)
  return ((completed / total) * 100).toFixed(0);
}

export function subsectionHasIncompleteFields(subsection, form) {
  for(let i = 0; i < subsection.fields.length; i++) {
    if (subsection.fields[i].conditionRef != null && subsection.fields[i].conditionRef != "") {
      const indexes = getFieldIndexes(subsection.fields[i].conditionRef, form);
      const conditionalField = subsection.fields[indexes[2]];

      // if it has a condition, see if that condition is met
      if (conditionalField.value == subsection.fields[i].condition) {
        if (subsection.fields[i].value == null || subsection.fields[i].value == "") {
          console.log('its trueee', subsection.fields[i])
          return true;
        }
      }
      continue;
    }
    if (subsection.fields[i]?.value == null || subsection.fields[i]?.value == "") {
      return true;
    }
  }
  return false;
}

export const isNullOrEmpty = (value) => {
  return value == '' || value == null;
}