import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { withContext } from "../context/Auth";
import logo from "../data/images/i46logo.png";
import SubNav from "../elements/SubNav";
import { Input } from "baseui/input";
import * as Theme from "../util/theme";

const Header = ({ context, history, noSearch = false }) => {
  const { user, clients, setActiveClient } = context;

  const [results, setResults] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  function onSearch(text) {
    if (!text || text.length === 0) return;
    const _results = [];
    for (let i = 0; i < clients.length; i++) {
      if (clients[i].name.toLowerCase().includes(text)) {
        _results.push(clients[i]);
      }
    }
    setIsOpen(true);
    setResults(_results);
  }

  function onSelect(client) {
    setActiveClient(client);
    history.push("/dashboard/" + client._id);
    setIsOpen(false);
  }

  return (
    <div
      style={{
        top: 0,
        left: 0,
        right: 0,
        height: 100,
        backgroundColor: "rgb(40,40,40)",
      }}
      className='flex flex-start items-center'
    >
      {user?.is === "user" && (
        <div style={{ left: 20, top: 24, position: "absolute" }}>
          <img src={logo} style={{ height: 48 }} alt='logo.png' />
        </div>
      )}

      <div className='relative left-0 top-0 w-64 bg-[rgb(210,210,210)]'>
        {user.is === "admin" && !noSearch && (
          <Input
            onBlur={() =>
              setTimeout(() => {
                setIsOpen(false);
              }, 200)
            }
            size='large'
            placeholder='Search'
            onChange={(e) => onSearch(e.target.value.toLowerCase())}
            overrides={{ InputContainer: { style: () => ({ backgroundColor: "rgb(210,210,210)" }) } }}
          />
        )}
        <div className='relative top-0 w-64' style={{ backgroundColor: "rgb(238,238,238)", zIndex: 1 }}>
          {isOpen &&
            results.map((c, i) => (
              <div className='p-4 cursor-pointer' style={{ backgroundColor: "rgb(238,238,238)", borderBottom: "4px solid " + Theme.TERTIARY, zIndex: 10 }} onClick={() => onSelect(c)}>
                <p className='font-bold' onClick={() => onSelect(c)}>
                  {c.name}
                </p>
                <p className='label' onClick={() => onSelect(c)}>
                  {c.email}
                </p>
              </div>
            ))}
        </div>
      </div>
      {user.is === "admin" && (
        <div className='ml-8 flex flex-row ' style={{ color: "white" }}>
          <p className='text-md cursor-pointer' onClick={() => history.push("/dashboard")}>
            Dashboard
          </p>
          <p className='text-md ml-8 cursor-pointer' onClick={() => history.push("/master")}>
            Master View
          </p>
        </div>
      )}
      {user?.is === "admin" && !noSearch && <SubNav onAddUser={() => history.push("/invite")} />}
    </div>
  );
};

export default withContext(withRouter(Header));
