import React, { Component } from "react";
import { StatefulTabs, Tab } from "baseui/tabs";
import { withRouter } from "react-router-dom";
import { withContext } from "../../context/Auth";
import { list, subscribe, updateSubscription, me } from "../../api/subscription-plan";
import { get } from "../../api/client";
import { me as myBankAccount } from "../../api/bank-account";
import SideNavForm from "../SideNavForm";
import SubscribeModal from "./SubscribeModal";
import * as Theme from "../../util/theme";
import FundView from "./FundView";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const isMobile = window.innerWidth < 768;

class ViewSubscriptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sectionIndex: 0,
      client: {},
      multiAssetFunds: [],
      singleAssetFunds: [],
      isModalOpen: false,
      activePlan: {},
      mySubscription: null,
      myBankAccounts: [],
      activeTab: "0",
    };
  }

  async componentDidMount() {
    await this.getClient()
    await this.getMySubscription();
    await this.getSubscriptionPlans();
    await this.getMyBankAccounts();
  }

  async getClient() {
    const { clientId } = this.props;

    try {
      const { data: client } = await get(clientId)
      this.setState({ client })
    } catch(e) {
      console.log(e)
    }
  }

  async getMySubscription() {
    const { activeClient } = this.props.context;

    try {
      const { data: mySubscription } = await me.get(activeClient._id);
      console.log(mySubscription);
      if(!mySubscription) return;
      await this.setState({ mySubscription });
    } catch (e) {
      console.log(e);
    }
  }

  async getMyBankAccounts() {
    const { activeClient } = this.props.context;

    try {
      const { data: myBankAccounts } = await myBankAccount.get(activeClient._id);
      this.setState({ myBankAccounts });
    } catch (e) {
      console.log(e);
    }
  }

  async getSubscriptionPlans() {
    const { data: plans } = await list();
    console.log(plans);
    const singleAssetFunds = plans.filter((p) => p.is_single_asset == true);
    const multiAssetFunds = plans.filter((p) => p.is_single_asset == false);
    this.setState({ singleAssetFunds, multiAssetFunds });
  }

  onSelectNavItem = (index) => {};

  onSubscribe = async (amount, bankAccountId) => {
    const { activePlan } = this.state;
    const { activeClient } = this.props.context;

    try {
      // hasnt subscribed, so subscribe now
      if (this.state.mySubscription?._id == null) {
        return await subscribe(activePlan._id, amount, bankAccountId, activeClient._id);
      }
      // if subscription id exists, then we are updating it
      if (this.state.mySubscription._id != null) {
        return await updateSubscription(this.state.mySubscription._id, amount, bankAccountId);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isModalOpen: false }, () => {
        this.getMySubscription()
      });
    }

  };

  render() {
    const { client, sectionIndex, mySubscription, singleAssetFunds, multiAssetFunds, myBankAccounts, activePlan, activeTab, isModalOpen } = this.state;
    
    return (
      <div class='flex flex-col md:flex-row min-h-screen' style={{ backgroundColor: "rgb(215,215,215)" }}>
        <SideNavForm subtitle={client?.name} title={"Subscriptions"} formId={""} activeIndex={sectionIndex} sections={[]} onSelect={this.onSelectNavItem} onBack={() => this.props.history.push("/dashboard/" + client?._id)} />

        <div class='flex flex-1 flex-col p-8 md:p-16 md:pt-12'>
          <StatefulTabs
            onChange={({ activeKey }) => this.setState({ activeTab: activeKey })}
            activeKey={activeTab}
            initialState={{ activeKey: "0" }}
            overrides={{
              TabBar: {
                style: { padding: "0", backgroundColor: "transparent", /*boxShadow: '4px 4px 4px rgb(200,200,200)',*/ borderBottom: "2px solid rgb(200,200,200)" },
              },
              TabContent: {
                style: { paddingLeft: 0, paddingRight: 0, fontSize: 32 },
              },
              TabHighlight: {
                style: { backgroundColor: Theme.SECONDARY },
              },
              Tab: { style: {
                fontSize: isMobile ? '20px' : '28px',
                fontWeight: 'bold'
              }},
            }}
          >
            <Tab title='Multi Asset' key='0'>
              {multiAssetFunds.map((p, i) => (
                <FundView key={p._id} plan={p} mySubscription={mySubscription?.plan_id == p._id ? mySubscription : null} onSelect={(_p) => this.setState({ isModalOpen: true, activePlan: _p })} />
              ))}
            </Tab>

            <Tab title='Single Asset' key='1'>
              {singleAssetFunds.map((p, i) => (
                <FundView key={p._id} plan={p} mySubscription={mySubscription?.plan_id == p._id ? mySubscription : null} onSelect={(_p) => this.setState({ isModalOpen: true, activePlan: _p })} />
              ))}
            </Tab>
          </StatefulTabs>
        </div>

        <SubscribeModal
          key={mySubscription?._id ?? Date.now()}
          plan={activePlan}
          mySubscription={mySubscription}
          isOpen={isModalOpen}
          onSubscribe={this.onSubscribe}
          onClose={() => this.setState({ isModalOpen: false })}
          fundName={activePlan.name}
          bankAccounts={myBankAccounts}
        />
      </div>
    );
  }
}

export default withContext(withRouter(ViewSubscriptions));
