import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Button } from "baseui/button"
import { find, update } from "../api/form"
import { get } from "../api/client"
import Section from "./Section"
import SideNavForm from "./SideNavForm"
import { on } from "../events/events"
import Loader from "react-loader-spinner"
import * as Theme from "../util/theme"
import { getFieldIndexes } from "../util/form"
import { withContext } from "../context/Auth"

class SectionManager extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      isFinished: false,
      hotFormId: "",
      sectionIndex: 0,
      subsectionIndex: 0,
      forms: [],
    }
  }

  async componentDidMount() {
    await this.getForms()
    await this.getClient()

    on("form:save", async () => await this.saveForm())
    on("form:onNextSubsection", async () => this.onNextSubsection())
    on("form:onPrevSubsection", async () => this.onPrevSubsection())
    // on('form:nextSection', () => this.set)
  }

  async getClient() {
    const { clientId } = this.props

    try {
      const { data: client } = await get(clientId)
      this.setState({ client })
    } catch (e) {
      console.log(e)
    }
  }

  getHotForm = () => {
    const { hotFormId, forms } = this.state
    const form = forms.find((f) => f._id == hotFormId)

    return form
  }

  async getForms() {
    const { clientId } = this.props

    try {
      const { data: forms } = await find({ client_id: clientId, type: this.props.location.state.type })

      await this.setState({ forms, hotFormId: forms[0]?._id, isFinished: false })
    } catch (e) {
      alert(e)
    }
  }

  onPrevSection = async () => {
    const { sectionIndex } = this.state

    await this.saveForm()

    if (sectionIndex === 0) return

    this.setState({ sectionIndex: sectionIndex - 1 })
  }

  onNextSection = async () => {
    const { sectionIndex } = this.state
    const form = this.getHotForm()

    await this.saveForm()

    if (sectionIndex === form.data.sections.length - 1) {
      return this.setState({ isFinished: true })
    }

    this.setState({ sectionIndex: sectionIndex + 1, subsectionIndex: 0, isFinished: false })
  }

  onPrevSubsection = async () => {
    if (this.state.subsectionIndex === 0) {
      this.onPrevSection()
      return this.setState({ subsectionIndex: 0 })
    }

    // trigger('form:save')
    this.setState({ subsectionIndex: this.state.subsectionIndex - 1 })
  }

  onNextSubsection = async () => {
    const { sectionIndex } = this.state
    const form = this.getHotForm()

    await this.saveForm()

    if (this.state.subsectionIndex >= form.data.sections[sectionIndex].subsections.length - 1) {
      await this.onNextSection()
      return this.setState({ subsectionIndex: 0 })
    }

    // trigger('form:save');
    this.setState({ subsectionIndex: this.state.subsectionIndex + 1 })
  }

  onSelectNavItem = (index) => {
    this.setState({ sectionIndex: index, subsectionIndex: 0 })
  }

  onInputField = async (section) => {
    const { hotFormId, forms, sectionIndex } = this.state

    const formIndex = forms.findIndex((f) => f._id == hotFormId)

    if (formIndex === -1) alert("No form index")

    forms[formIndex].data.sections[sectionIndex] = section

    await this.setState({ forms: forms, wasChangeMade: true })
  }

  saveForm = async () => {
    const { hotFormId, forms } = this.state
    console.log('user', this?.props?.context?.user)
    if (this.props.context.user.email.includes("compliance")) {
      return
    }
    await this.setState({ loading: true })

    try {
      const form = forms.find((f) => f._id == hotFormId)

      await update(hotFormId, form)
    } catch (e) {
      alert(e)
    } finally {
      await this.setState({ loading: false })
    }
  }

  canFieldBeRendered = (id, valueToMatch) => {
    const form = this.getHotForm()
    const indexes = getFieldIndexes(id, form)
    if (!indexes) return true
    if (!valueToMatch) return false

    const valueToCheck = form.data.sections[indexes[0]].subsections[indexes[1]].fields[indexes[2]].value

    // if fields match, it CANNOT be rendered
    return valueToCheck == valueToMatch
  }

  render() {
    const { hotFormId, sectionIndex, subsectionIndex, forms, client, loading, isFinished } = this.state
    const { clientId } = this.props
    const form = forms.find((f) => f._id == hotFormId)

    return (
      <div class="flex flex-col md:flex-row " style={{ backgroundColor: "rgb(220,220,220)" }}>
        <SideNavForm
          onBack={() => (window.location.pathname = "/dashboard/" + clientId)}
          backTo={"/dashboard/" + clientId}
          title={"Individual KYC"}
          subtitle={client?.name}
          formId={form?._id}
          activeIndex={sectionIndex}
          sections={form?.data?.sections}
          onSelect={this.onSelectNavItem}
        />
        {/* {form && <SideNavForm onBack={() => (window.location.pathname = "/dashboard")} backTo={'/dashboard/' + clientId} title={"Individual KYC"} subtitle={client?.name} formId={form?._id} activeIndex={sectionIndex} sections={form?.data?.sections} onSelect={this.onSelectNavItem} />} */}

        <div class="flex flex-1 flex-col">
          {/* <FormProgressBar forms={forms} hotFormId={hotFormId} onSelect={(id) => this.setState({ hotFormId: id })} /> */}

          <div class="container pt-32 xs:p-0 md:pl-12 min-h-screen" style={{ backgroundColor: "rgb(220,220,220)" }}>
            {form && !isFinished && (
              <Section
                client={client}
                form={form}
                section={form?.data?.sections[sectionIndex]}
                subsectionIndex={subsectionIndex}
                onInputField={this.onInputField}
                onNextSection={this.onNextSection}
                onPrevSection={this.onPrevSection}
                canFieldBeRendered={this.canFieldBeRendered}
              />
            )}
            {isFinished && (
              <div className="flex flex-initial flex-row p-16 justify-start">
                <div className="flex card p-8">
                  <p className="text-2xl font-bold">KYC complete</p>
                </div>

                <div
                  onClick={() => {
                    window.location = "/dashboard/" + clientId
                  }}
                  class="card flex flex-1 ml-4"
                  style={{ backgroundColor: Theme.PRIMARY, cursor: "pointer" }}
                >
                  <button
                    onClick={() => {
                      window.location = "/dashboard/" + clientId
                    }}
                    class="center center-vertical font-bold text-2xl"
                    style={{ color: "white" }}
                  >
                    To Dashboard
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {loading && (
          <div className="flex items-center justify-center" style={{ backgroundColor: "rgba(255,255,255,0.5)", position: "fixed", width: "100%", height: "100%" }}>
            <Loader color="black" />
          </div>
        )}
      </div>
    )
  }
}

export default withContext(withRouter(SectionManager))
