import { http, serialize } from './api';

const FORM = '/form';
const REVIEW = FORM + '/review';

export function find(query) {
    const q = serialize(query)

    return http.get(FORM + '?' + q)
}

export function update(formId, form) {
    const data = {
        form
    }

    return http.put(FORM + '/' + formId, data)
}

export function getReview(clientId, formType) {
    return http.get(REVIEW + '/' + clientId + '/' + formType)
}

export function review(client_id, form_id, form_type, status, created_by) {
    const data = {
        client_id,
        form_id,
        form_type,
        status,
        created_by
    }
    return http.post(REVIEW, data)
}