// export const PRIMARY = '#003300'
// export const SECONDARY = '#1b5e20'
// export const PRIMARY = "#1b5e20"; //'rgb(60, 128, 250)'
// export const SECONDARY = "#019444"; //'#06c75d'//'#3296ff'
export const TERTIARY = "#8bc34a";
export const BACKGROUND = "rgb(215,215,215)";

export const PRIMARY_DARK = "#003900";
/* #019444; */
export const SECONDARY = "#4c8c4a";
export const PRIMARY = "#006519";
