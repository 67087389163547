import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal";
import { KIND as ButtonKind, KIND } from "baseui/button";

const ReminderModal = ({ client, isOpen, review, onSend, onClose }) => {

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>Remind {client?.name} to complete their forms</ModalHeader>
      <ModalBody>
        <div class='flex flex-col'>
          <div>
            <p>Send an email reminder to {client?.name} to remind them that they have incomplete forms. We will automatically detect which forms are incomplete and send the reminder accordingly.</p>
            {/* <Select
              options={options}
              onChange={({ value }) => setStatus(value)}
              value={status}
            /> */}
          </div>
          {/* <div class='mt-4'>
            <p>Enter Subscription Amount</p>
            <Input startEnhancer='$' onChange={(e) => setAmount(e.target.value)} value={amount} />
          </div> */}
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={onClose} kind={KIND.secondary}>
          Close
        </ModalButton>
        <ModalButton onClick={() => onSend(client._id)}>Send</ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export default ReminderModal;
