import { http, serialize } from './api';

const CLIENT = '/client';
const INVITE = CLIENT + '/invite/';
const REMIND = CLIENT + '/remind/';
const REFRESHFORM = CLIENT + '/refresh-form'

export function create(client) {
    return http.post(CLIENT, client)
}

export function get(clientId) {
    return http.get(CLIENT + '/' + clientId)
}

export function find(query) {
    query = serialize(query)
    return http.get(CLIENT + '?' + query)
}

export function update(client) {
    return http.put(CLIENT, client)
}

export function updatePassword(clientId, password) {
    return http.put(CLIENT + '/' + clientId + '/password', { password })
}

export function updateOnboard(type, form, clientId) {
    const data = {
        type,
        form,
        client_id: clientId
    }

    return http.put(CLIENT + '/onboard', data)
}

export function finishOnboard(clientId) {
    const data = {
        client_id: clientId
    }
    return http.post(CLIENT + '/onboard', data)
}

export function getInvite(inviteId) {
    return http.get(INVITE + inviteId)
}

export function resendInvite(clientId) {
    const data = {
        client_id: clientId
    }
    return http.post(INVITE, data)
}

export function remind(clientId) {
    const data = {
        client_id: clientId
    }
    return http.post(REMIND, data)
}

export function updateInvite(inviteId, status) {
    const data = {
        status
    }
    return http.put(INVITE + inviteId, data)
}

export function refreshForm(clientId) {
    return http.post(CLIENT + '/' + clientId + '/refresh-form')
}