import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as Theme from "../util/theme";
import logo from "../data/images/i46logo.png";

class SideNav extends Component {
  render() {
    const { clients, user, activeClient, onSelect } = this.props;

    // const sortedClients = () => {
    //   const c = this.props.clients;
    //   for(let i = 0; i < c.length-1; i++) {
    //     for(let j = i+1; j < c.length; j++) {
    //       if(c[i].name > c[j].name) {
    //         const temp = c[i].name
    //         c[i].name = c[j].name
    //         c[j].name = temp
    //       }
    //     }
    //   }
    //   return c
    // }

    if(window.innerWidth < 960) {
      return <></>
    }

    return (
      <div class='relative min-h-screen md:flex'>
        <div class='bg-gray-800 text-gray-100 flex justify-between md:hidden'>
          <a href='/dashboard' class='block p-4 text-white font-bold'>
            Impact 46
          </a>

          <button class='mobile-menu-button p-4 focus:outline-none focus:bg-gray-700'>
            <svg class='h-5 w-5' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 6h16M4 12h16M4 18h16' />
            </svg>
          </button>
        </div>

        <div
          style={{ backgroundColor: 'rgb(40,40,40)' }}
          class='sidebar w-64 space-y-6 py-7  absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out'
        >
          <a href='/dashboard' class='text-white flex items-center justify-between space-x-2 px-4 pl-8'>
            {/* <svg class='w-8 h-8' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='2'
                d='M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z'
              />
            </svg> */}
            <img width='100px' style={{}} src={logo} alt='logo.png' />
            {/* <span class='text-2xl font-extrabold'>Jeffrey</span> */}
          </a>
          <div style={{ height: 32 }} />
          {user.is === "admin" && (
            <nav >
              {clients.map((client, i) => (
                <button
                  onClick={() => onSelect(client._id)}
                  class='pl-8 block mt-4 my-2 py-2.5 px-4 transition duration-200 hover:bg-green-600 hover:text-white w-full text-left'
                  style={activeClient._id === client._id ? { backgroundColor: Theme.SECONDARY, color: "white", fontWeight: "bold" } : { color: "white" }}
                >
                  {client.name}
                </button>
              ))}
              {/* <a href='/dashboard' class='block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white'>
              Abdullah Abdullah
              </a>
              <a href='/dashboard' class='block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white'>
              Khaleb Habib
              </a>
              <a href='/dashboard' class='block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white'>
              Amman al-Uzza
              </a>
              <a href='/dashboard' class='block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white'>
              Abbad
            </a> */}
            </nav>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(SideNav);
