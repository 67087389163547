import React, { useState } from "react";
import { FileUploader } from "baseui/file-uploader";

const FileInput = ({ onSelect, value }) => {
  const [progressAmount, startFakeProgress, stopFakeProgress] = useFakeProgress();

  return (
    <div class='flex flex-1 flex-col'>
      {/* {value == '' || !value && <></>} */}
      <FileUploader
        multiple={false}
        onCancel={stopFakeProgress}
        onDrop={(acceptedFiles, rejectedFiles) => {
          // handle file upload...
          startFakeProgress();
          onSelect(acceptedFiles[0]);
        }}
        // progressAmount is a number from 0 - 100 which indicates the percent of file transfer completed
        progressAmount={progressAmount}
        progressMessage={progressAmount ? `Uploading... ${progressAmount}% of 100%` : ""}
      />
      {value && <img src={value} width='200px' alt='license.jpg' />}
    </div>
  );
};

function useFakeProgress() {
  const [fakeProgress, setFakeProgress] = React.useState(0);
  const [isActive, setIsActive] = React.useState(false);
  function stopFakeProgress() {
    setIsActive(false);
    setFakeProgress(0);
  }
  function startFakeProgress() {
    setIsActive(true);
  }
  useInterval(
    () => {
      if (fakeProgress >= 100) {
        stopFakeProgress();
      } else {
        setFakeProgress(fakeProgress + 10);
      }
    },
    isActive ? 500 : null
  );
  return [fakeProgress, startFakeProgress, stopFakeProgress];
}

function useInterval(callback, delay) {
  const savedCallback = React.useRef(() => {});
  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default FileInput;
