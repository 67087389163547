import React from "react";
import { css } from "@emotion/css";

import * as Colors from "../util/theme";

export default function Button(props) {
  return (
    <>
      <button
        className={css([
          {
            backgroundColor: "hotpink",
            padding: "8px 40px",
            // height: 40,
            // padding: "8px",
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 700,
            fontSize: "1rem",
            transition: "0.3s all",
            color: props.color ?? "#fff",
            boxShadow: "none",
            cursor: "pointer",
            "&:hover:enabled": {
              cursor: "pointer",
              opacity: 0.5,
              // boxShadow: "4px 4px rgba(255,255,255,0.2)",
            },
            // "&:active": {
            //   boxShadow: "4px 4px rgba(255,255,255,0.2)",
            // },
            borderRadius: "20rem",
            // borderRadius: "2px",
            "@media(max-width: 480px)": {
              fontSize: "12px",
              padding: "4px 12px",
            },
          },

          props.type === "primary" && {
            background: "linear-gradient(180deg, #5481F1 0%, #7F33F1 100%)",
            border: "none",
          },

          props.type === "secondary" && {
            backgroundColor: "transparent",
            border: "2px solid " + props.color ?? "#fff",
            borderRadius: 32,
            paddingLeft: 20,
            paddingRight: 20,
            padding: "0px 28px",
            opacity: 0.8,
          },

          props.type === "tertiary" && {
            // backgroundColor: "#512da8",
            background: Colors.SECONDARY,
            border: "none",
            "&:hover:enabled": {
              opacity: 0.5,
              // background: 'rgba(200,200,200,0.5)'//"linear-gradient(180deg, #5481F1 0%, #7F33F1 100%)",
            },
            "&:hover:disabled": {
              cursor: "auto",
            },
          },

          props.type === "dark" && {
            background: 'rgb(40,40,40)',
            border: "none",
            "&:hover:enabled": {
              opacity: 0.5,
              // background: 'rgba(200,200,200,0.5)'//"linear-gradient(180deg, #5481F1 0%, #7F33F1 100%)",
            },
            "&:hover:disabled": {
              cursor: "auto",
            },
          },
        ])}
        onClick={props?.onClick}
        disabled={(props.disabled || props.isLoading == true) ?? false}
      >
        {props.isLoading ? (
          <p>
            Loading...<i class='fa fa-spinner fa-spin'></i>
          </p>
        ) : (
          props.text
        )}
      </button>
    </>
  );
}
