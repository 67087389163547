import { http } from './api';

const AUTH = '/auth'
const FORGOT_PASSWORD = AUTH + '/forgot-password'
const RESET_PASSWORD = AUTH + '/reset-password'

const LOGIN = AUTH + '/login';

export function authenticate(user) {
    return http.post(AUTH, user)
}

export function login(email, password) {
    const data = {
        email, password
    }
    return http.post(LOGIN, data)
}

export function forgotPassword(email) {
    const data = {
        email
    }
    return http.post(FORGOT_PASSWORD, data)
}

export function resetPassword(resetId, password) {
    const data = {
        password
    }
    return http.post(RESET_PASSWORD + '/' + resetId, data)
}

export function getResetPassword(resetId) {
    return http.get(RESET_PASSWORD + '/' + resetId)
}