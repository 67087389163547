import React, { Component } from "react";
import { withContext } from "../context/Auth";
import { withRouter } from "react-router-dom";
import { create } from "../api/client";
import { Input } from "baseui/input";
import Select from "../inputs/Select";
import * as Theme from "../util/theme";
import MoonLoader from "react-spinners/MoonLoader";
import logo from "../data/images/i46logo.png";

const options = ["Individual", "Corporation"];

class InviteClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      client_number: "",
      email: "",
      company_id: "",
      inviteUrl: "",
      isSubmit: false,
      loading: false,
      error: null,
    };
  }

  onSubmit = async () => {
    const user = this.state;
    const me = this.props.context.user;
    user.company_id = me.company_id;
    user.email = user.email.toLowerCase()
    
    if (!user.email.includes("@")) {
      return this.setState({ error: { message: "Email invalid" } });
    }

    this.setState({ isSubmit: true, loading: true });

    try {
      const { data } = await create(user);
      // const { invite } = data;
      this.props.history.push("/dashboard");
    } catch (e) {
      console.log(e);
      this.setState({ error: { message: "We had a problem adding this user, please check the information and try again." } });
    } finally {
      this.setState({ loading: false });
    }

    // console.log(invite);
    // const INVITE_URL = 'https://lucid-pasteur-0d394e.netlify.app/onboard/'
    // const INVITE_URL = "http://localhost:3000/onboard/"
    // this.setState({ inviteUrl: INVITE_URL + invite._id });
  };

  render() {
    const { isSubmit, loading, error } = this.state;

    return (
      <div class='min-h-screen pt-32' style={{ backgroundColor: Theme.PRIMARY }}>
        <div style={{ position: "absolute", left: 16, top: 16 }}>
          <img src={logo} width='100' />
        </div>
        {/* <div class='flex' id='onboard-header'>
            <p>Invite User</p>
          </div> */}
        {/* <div style={{marginLeft: 'auto', marginRight: 'auto'}}>

          </div> */}
          
          <div className="pl-48">
            <p class='text-5xl font-bold' style={{ color: "white", opacity: 0.7 }}>
              Invite Client
            </p>
            <p class='text-xl font-bold mb-4' style={{ color: "white", opacity: 0.7 }}>
              This sends the client a confirmation email
            </p>
          </div>
          <div class='flex card flex-col' style={{ marginLeft: "auto", marginRight: "auto" }}>
            <div class='mb-4'>
              <p class='label mb-2'>Name</p>
              <Input class='value' placeholder='Name' onChange={(e) => this.setState({ name: e.target.value, error: null })} />
            </div>
            
            <div class='mb-4'>
              <p class='label mb-2'>Client Number</p>
              <Input class='value' placeholder='Client Number' onChange={(e) => this.setState({ client_number: e.target.value, error: null })} />
            </div>

            <div class='mb-4'>
              <p class='label mb-2'>Email</p>
              <Input class='value' placeholder='Email' onChange={(e) => this.setState({ email: e.target.value, error: null })} />
            </div>

            {/* <div class='mb-4'>
            <p class='label mb-2'>Company</p>
            <Input class='value' placeholder='Company Name' />
          </div> */}

            {/* <div class='mb-4'>
            <p class='label mb-2'>Client Type</p>
            <Select options={options} placeholder='Select Type' onSelect={(value) => this.setState({ type: value[0]?.id })} />
          </div> */}

            {/* <div class='mb-4'>
            <p class='label mb-2'>Select Forms</p>
            <ButtonGroup onSelect={(selected) => this.setState({ forms: selected })} />
          </div> */}

            {error && <p style={{ color: "red" }}>{error.message}</p>}
            <div class='flex flex-1 flex-row mt-4'>
              <button disabled={loading} class='submit flex-1 faded mr-2' style={{ backgroundColor: "rgb(80,80,80)", color: "white" }} onClick={() => this.props.history.goBack()}>
                Back
              </button>
              <button disabled={loading} class='submit flex-1 faded mr-4' style={{ flex: 4 }} onClick={this.onSubmit}>
                Invite User
              </button>
              {loading && <MoonLoader width='100px' color={Theme.PRIMARY} />}
            </div>
          </div>

          {/* {isSubmit && (
          <div class='card mt-8'>
            <p class='label mb-2'>Copy this and send this in an email</p>
            <code>Click this link to open your account with Impact 46 {this.state.inviteUrl} </code>
            <br></br>
            <code> Email: {this.state.email}</code>
            <code> Password: abc123</code>
          </div>
        )} */}
      </div>
    );
  }
}

export default withContext(withRouter(InviteClient));
