import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { Checkbox } from "baseui/checkbox";
import { ButtonGroup } from "baseui/button-group";
import { Button } from "baseui/button";
import file from "../forms/agreement.pdf";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const NUM_PAGES = 23;
const pages = [];
for (let i = 0; i < 23; i++) {
  pages.push(0);
}

const AccountType = ({ client, onUpdate, toggleNavigation }) => {
  const [selected, setSelected] = useState(-1);

  useEffect(() => {
    if (client?.agreement?.isAgreementChecked) {
      return toggleNavigation(true);
    }
    return toggleNavigation(false);
  }, [client?.agreement?.isAgreementChecked]);

  useEffect(() => {
    console.log(selected);
    toggleNavigation(selected >= 0);
    if (selected === 0) return onUpdate("individual");
    if (selected === 1) return onUpdate("corporation");
  }, [selected]);

  return (
    <div class='flex flex-col md:flex-row justify-between items-center p-4'>
      <h1 class='text-md md:text-lg font-bold'>Welcome {client.name}! <br/>Which type of account do you want to open?</h1>
      <div class='mt-8 flex'>
        <ButtonGroup
          mode='radio'
          selected={selected}
          onClick={(e, i) => {
            setSelected(i);
          }}
        >
          <Button>Individual</Button>
          <Button>Corporation</Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default AccountType;
