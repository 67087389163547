import React, { Component } from "react"
import PropTypes from "prop-types"

// import json from "../fields/inv_acc_ope_for.json";
// import "tailwindcss/tailwind.css"
import { Input } from "baseui/input"
import { Button } from "baseui/button"
import { trigger } from "../events/events"
import { subsectionHasIncompleteFields } from "../util/form"

import SubSection from "./SubSection"
import * as Theme from "../util/theme"

class Section extends Component {
  static propTypes = {
    section: PropTypes.objectOf({
      title: PropTypes.string,
      subsections: PropTypes.array,
    }),
    onPrevSection: PropTypes.func,
    onNextSection: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      hasIncompleteFields: true,
    }
  }

  componentDidMount() {}

  onPrev() {
    trigger("form:onPrevSubsection")
  }

  onNext = () => {
    const { section, subsectionIndex } = this.props

    // Check for errors first
    const hasIncompleteFields = subsectionHasIncompleteFields(section.subsections[subsectionIndex], this.props.form)

    if (hasIncompleteFields) {
      console.log(hasIncompleteFields)
      return this.setState({ hasIncompleteFields: true })
    }
    this.setState({ hasIncompleteFields: false })
    trigger("form:onNextSubsection")
  }

  onInputField = (field, value, type = null) => {
    const { section, subsectionIndex, onInputField } = this.props
    // const { subsectionIndex } = this.state;
    if(field == null) return alert('field id is null')

    const fieldIndex = section.subsections[subsectionIndex].fields.findIndex((f) => f.id == field)

    if (fieldIndex === -1) return alert("no field index")

    // NOTE if updating a phone's country code, pass in type=phone and it will update country on the phone field
    if (type == "phone") {
      section.subsections[subsectionIndex].fields[fieldIndex] = {
        ...section.subsections[subsectionIndex].fields[fieldIndex],
        country: value,
      }
    } else {
      section.subsections[subsectionIndex].fields[fieldIndex] = {
        ...section.subsections[subsectionIndex].fields[fieldIndex],
        value,
      }
    }

    this.setState({ wasChangeMade: true })
    onInputField(section)
  }

  onAutofill = () => {
    const { section, subsectionIndex, onInputField } = this.props
    for (let i = 0; i < section.subsections[subsectionIndex].fields.length; i++) {
      if (section.subsections[subsectionIndex].fields[i].value == null && section.subsections[subsectionIndex].fields[i].d != null) {
        section.subsections[subsectionIndex].fields[i].value = section.subsections[subsectionIndex].fields[i].d
        // onInputField(section)
      }
    }
    this.setState({ wasChangeMade: true })
    onInputField(section)
    setTimeout(() => {
      this.onNext()
    }, 500)
  }

  render() {
    // form is only passed so it can be used to check for incomplete fields
    const { section, subsectionIndex, client, canFieldBeRendered } = this.props
    const { hasIncompleteFields } = this.state;

    return (
      <div class="flex-initial p-4 md:p-8 mt-4 mb-16 card ">
        {/* <p class="text-3xl font-bold mb-8">{section.title}</p> */}

        <div class="flex flex-end justify-end mr-8">
          <p onClick={this.onAutofill} class="text-md font-bold" style={{ color: "rgba(140,140,140,0.5)", borderBottom: "2px solid", cursor: "pointer" }}>
            AUTOFILL
          </p>
        </div>
        <SubSection subsection={section.subsections[subsectionIndex]} onInputField={this.onInputField} client={client} canFieldBeRendered={canFieldBeRendered} hasIncompleteFields={hasIncompleteFields} />

        <div class="justify-around flex flex-row flex-1">
          {subsectionIndex !== 0 && (
            <div onClick={this.onPrev} class="card flex flex-1 mt-4 ml-4" style={{ backgroundColor: "rgb(50,50,50)", cursor: "pointer" }}>
              <button onClick={this.onClickBack} class="center center-vertical font-bold text-xl" style={{ color: "white" }}>
                Back
              </button>
            </div>
          )}
          <div onClick={this.onNext} class="card flex flex-1 mt-4 ml-4" style={{ backgroundColor: Theme.PRIMARY, cursor: "pointer" }}>
            <button onClick={this.onClickNext} class="center center-vertical font-bold text-xl" style={{ color: "white" }}>
              Next
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Section
