import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { withContext } from "../context/Auth"
import { find, get, update } from "../api/client"
import { invite } from "../api/user"
import { me as myBankAccount } from "../api/bank-account"
import { me as mySubscription } from "../api/subscription-plan"
import { StatefulTabs, Tab, FILL } from "baseui/tabs-motion"
import SideNav from "./SideNav"
import SubNav from "../elements/SubNav"
import Avatar from "../elements/Avatar"
import { refreshForm } from "../api/client"
import * as Theme from "../util/theme"
import KYC from "./KYC/KYC"
import Subscription from "./Subscription/Subscription"
import BankAccount from "./BankAccount/BankAccount"
import logo from "../data/images/i46logo.png"
import AccountNotActive from "../elements/AccountNotActive"
import AddUserToClientModal from "./AddUserToClientModal"
import Header from "./Header"

const isMobile = window.innerWidth <= 960
const isSmMobile = window.innerWidth <= 440

class Dashboard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // client: {},
      clients: [],
      loading: true,
      activeTab: 0,
      isKycApproved: false,
      isFatcaApproved: false,
      isAmlApproved: false,
      myBankAccounts: null,
      mySubscription: null,
      isAddUserToClientOpen: false,
    }
  }

  async componentDidMount() {
    await this.getClients()
    // await this.getMySubscription()
    // await this.getBankAccounts()

    if (this.state.clients.length > 0) {
      if (this.props.clientId != null && this.props.clientId != "") {
        let _active = this.state.clients.find((c) => c._id == this.props.clientId)
        this.props.context.setActiveClient(_active)
        if (_active) return this.props.history.push("/dashboard/" + this.state.clients[0]._id)
      }
      if (!this.props.context.activeClient?._id) {
        this.props.context.setActiveClient(this.state.clients[0])
      }
    }

    if (!this.props.context.isAuthenticated) {
      return this.props.history.push("/login")
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeClient, isAuthenticated, mySubscription, myBankAccounts } = this.props.context
    const { history } = this.props
    if (!isAuthenticated) {
      return history.push("/login")
    }

    /**
     * This is a little UI thing that has to be done maually bc the BaseUI component doesnt expose it to us
     * Best to just ignore this
     */
    const elements = document.getElementsByTagName("button")
    const tabpanels = document.getElementsByTagName("div")
    for (let i = 0; i < tabpanels.length; i++) {
      if (tabpanels[i].id.includes("-tabpanel-")) {
        tabpanels[i].style.paddingLeft = "0px"
        tabpanels[i].style.paddingRight = "0px"
      }
    }
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].id.includes("-tab-")) {
        elements[i].style.backgroundColor = "rgb(230,230,230)"
        // elements[i].style.opacity = 0.5;
        elements[i].style.fontWeight = "bold"
      }

      if (elements[i].id.includes("-tab-3") && myBankAccounts == null) {
        if (document.getElementById("bank-indicator") == null) {
          const node = document.createElement("div", {})
          node.id = "bank-indicator"
          node.style = "display: relative; margin-left: 8px; height: 12px; width: 12px; border-radius: 6px; background-color: #8bc34a"
          elements[i].appendChild(node)
        }
      }

      if (elements[i].id.includes("-tab-2") && !mySubscription) {
        if (document.getElementById("sub-indicator") == null) {
          const node = document.createElement("div", {})
          node.id = "sub-indicator"
          node.style = "display: relative; margin-left: 8px; height: 12px; width: 12px; border-radius: 6px; background-color: #8bc34a"
          elements[i].appendChild(node)
        }
      }
    }

    // When clientId switches, update client on the state
    if (prevProps.context.activeClient._id != activeClient._id) {
      history.push("/dashboard/" + activeClient._id)
    }
  }

  /**
   * Get all clients of a user
   */
  async getClients() {
    const { user, setClients } = this.props.context
    try {
      if (user.is === "admin") {
        let { data: clients } = await find({ company_id: user?.company_id })
        setClients(clients)
        return await this.setState({ clients, loading: false })
      } else if (user.is === "user") {
        const { data: client } = await get(user.client_id)
        setClients([client])
        return await this.setState({ clients: [client], loading: false })
      }
    } catch (e) {
      console.log(e)
      await this.setState({ loading: false })
    } finally {
      await this.setState({ loading: false })
    }
  }

  onViewForm = () => {
    this.props.history.push("/form")
  }

  onAddUser = () => {
    this.props.history.push("/invite")
  }

  onSelectClient = (clientId) => {
    if (clientId === this.props.clientId) return

    const client = this.state.clients.find((c) => c._id == clientId)
    document.getElementById("sub-indicator")?.remove()
    document.getElementById("bank-indicator")?.remove()

    setTimeout(() => {
      this.props.context.setActiveClient(client)
    }, 250)
  }

  onAddUserToClient = async (user) => {
    console.log(user)
    this.setState({ isAddUserToClientOpen: false })

    try {
      await invite(user)
    } catch (e) {
      console.log(e)
    }
  }

  onTabSwitch = ({ activeKey }) => {
    this.setState({ activeTab: activeKey })
  }

  render() {
    const { activeClient, user } = this.props.context
    const { clients, activeTab, isKycApproved, loading } = this.state
    console.log(activeClient, loading);
    return (
      <div class="flex flex-row " style={{ backgroundColor: Theme.BACKGROUND }}>
        {/* <Header /> */}
        {user.is === "admin" && <SideNav user={user} clients={clients} activeClient={activeClient} onSelect={this.onSelectClient} />}

        {/* {clients.length == 0 && !loading && <EmptyClients onClick={this.onAddUser} />} */}

        <div class="flex flex-1 flex-col" style={{ backgroundColor: Theme.BACKGROUND }}>
          <Header />

          <div
            class={"container p-8 lg:p-32 lg:pt-16 xs:p-8 pt-20"}
            style={user.is === "user" ? { padding: !isMobile ? 32 : 8, paddingLeft: !isMobile ? 64 : 8, paddingRight: !isMobile ? 64 : 8 } : {}}
          >
            <div className="flex flex-row justify-between items-center">
              <div>
                <h1 class="text-4xl font-bold">{activeClient?.name}</h1>
                <p className="label ">
                  {activeClient?.email} {activeClient?.client_number && "#" + activeClient?.client_number}
                </p>
              </div>
              <div>
                <button
                  style={{ backgroundColor: Theme.TERTIARY, color: "white", padding: 12, paddingLeft: 20, paddingRight: 20, borderRadius: 32 }}
                  onClick={() => this.setState({ isAddUserToClientOpen: !this.state.isAddUserToClientOpen })}
                >
                  Add Client
                </button>
              </div>
            </div>
            {/* <p className="label ">{activeClient.client_number && '#' + activeClient.client_number}</p> */}

            {activeClient?._id && activeClient?.is_active && (
              <div class="flex flex-col flex-1 mt-4" style={{ zIndex: 1000 }}>
                <StatefulTabs
                  // fill={FILL.fixed}
                  onChange={({ activeKey }) => this.setState({ activeTab: activeKey })}
                  activeKey={activeTab}
                  initialState={{ activeKey: "1" }}
                  overrides={{
                    TabBar: {
                      style: { padding: "8px", /*boxShadow: '4px 4px 4px rgb(200,200,200)',*/ borderBottom: "2px solid rgb(200,200,200)" },
                    },
                    TabHighlight: {
                      style: { backgroundColor: Theme.SECONDARY },
                    },
                    TabBorder: {
                      style: { backgroundColor: "rgba(180,180,180, 0.5)" },
                    },
                    TabList: {
                      style: { backgroundColor: "transparent" },
                    },
                    TabContent: {
                      style: { paddingLeft: 0, paddingRight: 0, padding: 0 },
                    },
                    TabPanel: {
                      style: { paddingLeft: 0, paddingRight: 0, padding: 0 },
                    },
                    // Tab: {component: TabOverride, style: tabStyle},
                  }}
                >
                  {/* <Tab title='KYC & FATCA' key='1' overrides={{ Tab: { style: { padding: 0 }}}}> */}
                  <Tab title="KYC & FATCA" key="1">
                    <KYC
                      user={user}
                      client={activeClient}
                      toKyc={() => this.props.history.push({ pathname: "/form/" + activeClient._id, state: { type: "kyc_" + activeClient.type } })}
                      toFatca={() => this.props.history.push({ pathname: "/fatca/" + activeClient._id, state: { type: "fatca_" + activeClient.type } })}
                      toAml={() => this.props.history.push({ pathname: "/aml/" + activeClient._id, state: { type: "aml" } })}
                      setIsKycApproved={(isApproved) => this.setState({ isKycApproved: isApproved })}
                      setIsFatcaApproved={(isApproved) => this.setState({ isFatcaApproved: isApproved })}
                      setIsAmlApproved={(isApproved) => this.setState({ isAmlApproved: isApproved })}
                    />
                  </Tab>
                  <Tab title="Subscription" key="2">
                    <Subscription client={activeClient} toForm={(path) => this.props.history.push(`/${path}/` + activeClient._id)} />
                  </Tab>
                  <Tab title="Bank Accounts" key="3">
                    <BankAccount client={activeClient} toForm={() => this.props.history.push("/bank-account/" + activeClient._id)} />
                  </Tab>
                </StatefulTabs>
              </div>
            )}

            {!activeClient.is_active && !loading && <AccountNotActive client={activeClient} />}
          </div>
        </div>

        <Avatar />

        {/* {!user.is_client && <SubNav onAddUser={this.onAddUser} />} */}

        {/* <button style={{
            position: 'absolute',
            top: 200, right: 700,
            backgroundColor: 'red',
            color: 'white',
            height: 200
          }} onClick={() => this.props.history.push({ pathname: '/fatca/' + activeClient._id, state: { client: activeClient } })}>
            FATCA
          </button>
        
        <button style={{
            position: 'absolute',
            top: 200, right: 300,
            backgroundColor: 'blue',
            color: 'white',
            height: 200
          }} onClick={() => this.props.history.push({ pathname: '/aml/' + activeClient._id, state: { client: activeClient } })}>
            AML
          </button>
        */}
        {/* DONT DELETE THIS COMMENTED OUT BUTTON, WE USE IT TO MANUALLY REFRESH FORMS IF WE CHANGE SOMETHING IN THE SERVER */}
        {/* <button style={{position: 'absolute', right: 32,top: 100, height: 200, width: 200, backgroundColor: 'green' }} onClick={() => refreshForm(activeClient._id)}>REFRESH</button>  */}
        <AddUserToClientModal
          client={activeClient}
          isOpen={this.state.isAddUserToClientOpen}
          onSend={this.onAddUserToClient}
          onClose={() => this.setState({ isAddUserToClientOpen: !this.state.isAddUserToClientOpen })}
        />
      </div>
    )
  }
}

export default withContext(withRouter(Dashboard))
