import React from "react";
import * as Theme from "../../util/theme";
import { formatDate, formatShortDate } from "../../util/datetime";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const FundView = ({ plan, mySubscription, onSelect }) => {
  const renderAction = () => {
    if (plan.exit.length > 0) return <></>;
    if (!plan.is_active)
      return (
        <div class='flex justify-around items-center mt-8 md:ml-10 md:mr-8' style={{ height: 64, backgroundColor: "rgba(140,140,140,0.5)", color: "rgba(140,140,140,0.7)" }}>
          <p class='font-bold text-md md:p-4'>Fund Closed</p>
          <p class='font-bold text-sm md:pr-4'>{formatShortDate(plan.closed_at)}</p>
        </div>
      );

    if (!mySubscription) {
      return (
        <button class='subscription-button mt-8 md:ml-10 md:w-4/5' onClick={() => onSelect(plan)}>
          Subscribe
        </button>
      );
    }

    return (
      <div class='flex flex-row justify-between md:pl-10 md:pr-10 flex-wrap'>
        <div class='flex flex-col mt-4'>
          <p class='label'>Subscription Amount</p>
          <p class='value font-bold'>SAR {mySubscription.amount.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
        </div>
        <div class='flex flex-col mt-4'>
          <p class='label'>Subscription Date</p>
          <p class='value font-bold'>{formatDate(mySubscription.created_at)}</p>
        </div>
        <div class='flex flex-col mt-4'>
          <p class='label'>Account Used</p>
          <p class='value font-bold'>
            {mySubscription.bank_account.bank} ({mySubscription.bank_account.iban.substring(mySubscription.bank_account.iban.length - 4, mySubscription.bank_account.iban.length)})
          </p>
        </div>

        <div class='flex flex-col mt-4'>{/* <p >Subscribed</p> */}</div>
        {/* <button class='subscription-button mt-4' onClick={() => onSelect(plan)}>{mySubscription ? "Edit Subscription" : "Subscribe"}</button> */}
      </div>
    );
    // return (
    //   <button class='subscription-button' disabled={!plan.is_active} onClick={() => onSelect(plan)}>
    //     {plan.is_active ? "Edit Subscription" : "Funding Closed"}
    //   </button>
    // );
  };

  const percFunded = () => {
    const perc = (plan.current_size / plan.size) * 100;
    return perc;
  };

  const dollarToMillions = (value) => {
    const dollars = value.toString();
    if (dollars.length === 5) return "0.0" + dollars.substring(0, 1);
    if (dollars.length === 6) return "0." + dollars.substring(0, 2);
    if (dollars.length === 7) return dollars.substring(0, 1);
    if (dollars.length === 8) return dollars.substring(0, 2);
    if (dollars.length === 9) return dollars.substring(0, 3);
  };

  const allocationRemaining = () => {
    const remaining = plan.size - plan.current_size;
    if (remaining.toString().length <= 5) return "SAR 0";

    return "SAR " + dollarToMillions(remaining) + " million";
  };

  if (!plan || !plan._id) return <div></div>;

  return (
    <div class='card flex mb-16 w-full max-w-none' style={{ position: "relative" }}>
      <div class='flex flex-col md:flex-row'>
        <div class='flex flex-1 flex-col justify-start md:pl-4 pt-4'>
          {plan.logo.length > 0 ? <img alt='logo.png' src={plan.logo} width='200px' /> : <p class='text-3xl font-bold'>{plan.name}</p>}
          {/* <p class='text-lg font-bold faded' style={{ borderBottomColor: "rgb(180,180,180)", borderBottomWidth: 2 }}>
                  $500,000 - $1,000,000
                </p> */}

          <div class='mt-8 faded' style={{ maxWidth: "100%" }}>
            <p>{plan.description}</p>
          </div>

          <div class='hidden md:flex flex-col'>
            <p class='mt-8 label'>Supporting Documents</p>
            <div class='flex flex-row flex-wrap mt-2' style={{ width: 480 }}>
              <p class='tag'>Fund_Overview.pdf</p>
              <p class='tag'>i46_companies.pdf</p>
              <p class='tag'>portfolio_of_companies.pdf</p>
              <p class='tag'>agreement_form.pdf</p>
            </div>
          </div>
        </div>

        <div class='flex flex-col flex-1 md:pl-12 pt-4'>
          {/* Capital allocation and progress circle */}
          <div class='flex flex-row justify-between md:items-center'>
            <div class='flex flex-col md:pl-10'>
              <div class=''>
                <p class='text-sm md:text-xl label'>Target Capital</p>
                <p class='text-sm md:text-xl value font-bold'>SAR {dollarToMillions(plan.size)} million</p>
              </div>
              <div class='md:mt-4'>
                <p class='text-sm md:text-xl label'>Allocation Remaining</p>
                <p class='text-sm md:text-xl value font-bold'>{allocationRemaining()}</p>
              </div>
            </div>
            {/* <div style={{ height: 144, width: 144, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: Theme.PRIMARY, borderRadius: 72 }}> */}
            <div class='md:mr-10 h-20 w-20 md:h-28 md:w-28'>
              <CircularProgressbar
                value={plan.is_active ? percFunded() : 100}
                text={plan.is_active ? percFunded().toFixed(0) + "% funded" : "100% Full"}
                color={Theme.PRIMARY}
                styles={buildStyles({
                  textColor: Theme.PRIMARY,
                  trailColor: "rgba(0,0,0,0.2)",
                  pathColor: Theme.PRIMARY,
                  backgroundColor: Theme.PRIMARY,
                  backgroundPadding: 4,
                  textSize: 12,
                })}
              />
              {/* </div> */}
            </div>
          </div>

          {/* Sectors */}
          <div class='md:pl-10'>
            <p class='mt-8 label'>Sectors</p>
            <div class='flex flex-row flex-wrap mt-2'>
              {plan.sectors.map((s, i) => (
                <p key={i} class='tag'>
                  {s}
                </p>
              ))}
            </div>
          </div>

          {plan.exit != "" && (
            <div class='flex flex-row justify-between items-center'>
              <div class='md:pl-10'>
                <p class='mt-8 label'>Exit Method</p>
                <p class='font-bold'>{plan.exit}</p>
              </div>

              <div class='md:pr-20'>
                <p class='mt-8 label'>Exit Date</p>
                <p class='font-bold'>{formatDate(plan.closed_at)}</p>
              </div>
            </div>
          )}

          {renderAction()}
        </div>
      </div>
    </div>
  );
};

export default FundView;
