import React, { Component } from "react";
import { Input, MaskedInput } from "baseui/input";
import { create } from "../../api/bank-account";
import { withContext } from "../../context/Auth";
import * as Theme from "../../util/theme";
import SideNavForm from "../SideNavForm";
import MoonLoader from "react-spinners/MoonLoader";

class BankAccountForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // client: {},
      loading: false,
      submitting: false,
      account: {},
      error: null
    };
  }

  componentDidMount() {}

  onSubmit = async () => {
    if(this.state.submitting) return;
    
    const { activeClient } = this.props.context;

    if(!this.state.account.iban) return this.setState({ error: 'IBAN must be atleast 16 characters'})
    if(this.state.account?.name_on_account?.length < 1) return this.setState({ error: 'You must enter your name'})
    if(this.state.account?.iban?.length < 1) return this.setState({ error: 'IBAN must be atleast 16 characters'})
    if(this.state.account?.bank?.length < 1) return this.setState({ error: 'You must enter a bank name'})

    this.setState({ submitting: true });
    try {
      const { data } = await create({ ...this.state.account, client_id: activeClient._id });
      window.location.pathname = "/dashboard/" + activeClient._id;
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ submitting: false })
    }
  };

  onChange = (field, value) => {
    this.setState({ account: { ...this.state.account, [field]: value }, error: null });
  };

  render() {
    const { submitting, error } = this.state;
    const { activeClient } = this.props.context;

    return (
      <div class='flex flex-col md:flex-row min-h-screen' style={{ backgroundColor: Theme.BACKGROUND }}>
        <SideNavForm subtitle={activeClient?.name} title={"Accounts"} formId={""} activeIndex={0} sections={[]} onSelect={this.onSelectNavItem} onBack={() => window.location.pathname = '/dashboard/'+activeClient?._id} />

        <div class='flex flex-1 flex-col p-8 md:p-16 md:pt-32' style={{ backgroundColor: "rgb(220,220,220)" }}>
          <div class='flex-initial p-8 mt-4 mb-16 card'>
            <div class='mb-4 p-2 flex flex-col md:flex-row justify-between'>
              <div class='flex-1 px-4'>
                <p class='mb-2 label' style={{ color: "rgb(140,140,140)" }}>
                  Name on Account
                </p>
                <Input placeholder={"John Smith"} onChange={(e) => this.onChange("name_on_account", e.target.value)} />
              </div>
              <div class='flex-1 px-4 mt-8 md:mt-0'>
                <p class='mb-2 label' style={{ color: "rgb(140,140,140)" }}>
                  Bank Name
                </p>
                <Input placeholder={"Morgan Stanley"} onChange={(e) => this.onChange("bank", e.target.value)} />
              </div>
            </div>
            {/* <div class='mb-4 p-2 flex flex-row justify-between'> */}
              {/* <div class='flex-1 px-4'>
                <p class='mb-2 label' style={{ color: "rgb(140,140,140)" }}>
                  Account Number
                </p>
                <Input placeholder={"123456789"} onChange={(e) => this.onChange("account_number", e.target.value)} />
              </div> */}
              <div class='flex-1 px-4'>
                <p class='mb-2 label' style={{ color: "rgb(140,140,140)" }}>
                  IBAN #
                </p>
                <MaskedInput type='text' placeholder={'SA07 0123 4567 8910 1234 5678'} onChange={(e) => this.onChange("iban", e.target.value)} mask='SA99 9999 9999 9999 9999 9999' />
                {/* <Input placeholder={"0987654321"} onChange={(e) => this.onChange("iban", e.target.value)} /> */}
              </div>
            {/* </div> */}
              <p className='ml-4' style={{color:'red'}}>{error}</p>
            <div class='justify-around flex flex-row flex-1 p-4 px-4'>
              <div onClick={this.onSubmit} class='card flex flex-1 mt-4 ml-4' style={{ backgroundColor: Theme.PRIMARY, cursor: "pointer" }}>
                <button onClick={this.onSubmit} class='center center-vertical font-bold text-xl' style={{ color: "white" }}>
                  Add Bank Account
                </button>
              </div>
              {submitting && <MoonLoader color={Theme.PRIMARY} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withContext(BankAccountForm);
