const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export function formatDate(date) {
    const d = new Date(date)

    const month = months[d.getMonth()]
    const day = d.getDate()
    const year = d.getFullYear()

    return day + ' ' + month + ' ' + year;
}

export function formatShortDate(date) {
    const d = new Date(date)

    const month = shortMonths[d.getMonth()]
    const day = d.getDate()
    const year = d.getFullYear()

    return day + ' ' + month + ' ' + year;
}