import React from "react";
import { Input } from "baseui/input";
import { Button } from "baseui/button";
import FileInput from "./FileInput";
import { upload } from "../api/aws";

export const CountryActivityTable = ({ value, onChange }) => {
  return (
    <div class='flex flex-col'>
      {value.map((v, i) => (
        <div key={i}>
          <div class='flex flex-row justify-around'>
            {i === 0 && <p class='label'>Countries you deal with</p>}
            {i === 0 && <p class='label'>Nature of activity</p>}
          </div>
          <div class='flex flex-col md:flex-row mb-2' style={{ backgroundColor: "rgb(222,222,222)", padding: 12, borderRadius: 2 }}>
            <p className='label flex self-center mr-4 text-2xl'>{i + 1}.</p>
            <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
              <Input
                type='text'
                key={i}
                placeholder={"Country"}
                value={v.country}
                onChange={(e) => {
                  const arr = value;
                  arr[i].country = e.target.value;
                  onChange(arr);
                }}
              />
            </div>
            <div class='flex flex-1'>
              <Input
                type='text'
                key={i}
                placeholder={"Activity"}
                value={v.activity}
                onChange={(e) => {
                  const arr = value;
                  arr[i].activity = e.target.value;
                  onChange(arr);
                }}
              />
            </div>
          </div>
        </div>
      ))}
      <div class='flex mt-4'>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { country: "", activity: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const PoliticalPersonsTable = ({ value, onChange }) => {
  return (
    <div class='flex flex-col'>
      {value.map((v, i) => (
        <div class='flex flex-col md:flex-row mb-2' style={{ backgroundColor: "rgb(222,222,222)", padding: 12, borderRadius: 2 }}>
          <p className='label flex self-center mr-2 text-2xl'>{i + 1}.</p>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              type='text'
              key={i}
              placeholder={"Name"}
              value={v.name}
              onChange={(e) => {
                const arr = value;
                arr[i].name = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              type='text'
              key={i}
              placeholder={"Relative Relation"}
              value={v.relation}
              onChange={(e) => {
                const arr = value;
                arr[i].relation = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              type='text'
              key={i}
              placeholder={"Job / Gov. Degree"}
              value={v.degree}
              onChange={(e) => {
                const arr = value;
                arr[i].degree = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              type='text'
              key={i}
              placeholder={"Employer's Name"}
              value={v.employer}
              onChange={(e) => {
                const arr = value;
                arr[i].employer = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1'>
            <Input
              type='text'
              key={i}
              placeholder={"ID No."}
              value={v.id_no}
              onChange={(e) => {
                const arr = value;
                arr[i].id_no = e.target.value;
                onChange(arr);
              }}
            />
          </div>
        </div>
      ))}
      <div class='flex mt-4 justify-end'>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { country: "", activity: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const TaxResidenceTable = ({ value, onChange, onBlur = () => {} }) => {
  return (
    <div class='flex flex-col'>
      {value.map((v, i) => (
        <div class='flex flex-col md:flex-row mb-2' style={{ backgroundColor: "rgb(222,222,222)", padding: 12, borderRadius: 2 }}>
          <p className='label flex self-center mr-2 text-2xl'>{i + 1}.</p>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Country/Jurisdiction"}
              value={v.country}
              onChange={(e) => {
                const arr = value;
                arr[i].country = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Taxpayer ID Number (TIN) or Equivalent"}
              value={v.tin}
              onChange={(e) => {
                const arr = value;
                arr[i].tin = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"If no TIN, enter reason A, B, or C"}
              value={v.no_tin}
              onChange={(e) => {
                const arr = value;
                arr[i].no_tin = e.target.value;
                onChange(arr);
              }}
            />
          </div>
        </div>
      ))}
      <div class='flex mt-4'>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { country: "", tin: "", no_tin: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const ManagerExecutiveTable = ({ value, onChange, onBlur = () => {} }) => {
  return (
    <div class='flex flex-col'>
      {value.map((v, i) => (
        <div class='flex flex-col md:flex-row mb-2' style={{ backgroundColor: "rgb(222,222,222)", padding: 12, borderRadius: 2 }}>
          <p className='label flex self-center mr-2 text-2xl'>{i + 1}.</p>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Name of Manager"}
              value={v.name}
              onChange={(e) => {
                const arr = value;
                arr[i].name = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Expiry Date"}
              value={v.expiry}
              onChange={(e) => {
                const arr = value;
                arr[i].expiry = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"ID Number"}
              value={v.id}
              onChange={(e) => {
                const arr = value;
                arr[i].id = e.target.value;
                onChange(arr);
              }}
            />
          </div>
        </div>
      ))}
      <div class='flex justify-end mt-4'>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { name: "", expiry: "", id: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const OwnershipStructureTable = ({ value, onChange, onBlur = () => {} }) => {
  return (
    <div class='flex flex-col'>
      {value.map((v, i) => (
        <div class='flex flex-col md:flex-row mb-2' style={{ backgroundColor: "rgb(222,222,222)", padding: 12, borderRadius: 2 }}>
          <p className='label flex self-center mr-2 text-2xl'>{i + 1}.</p>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Name of Owner"}
              value={v.name}
              onChange={(e) => {
                const arr = value;
                arr[i].name = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='number'
              key={i}
              placeholder={"Ownership Percentage"}
              value={v.perc}
              onChange={(e) => {
                const arr = value;
                arr[i].perc = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Expiry Date"}
              value={v.expiry}
              onChange={(e) => {
                const arr = value;
                arr[i].expiry = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"ID Number"}
              value={v.id}
              onChange={(e) => {
                const arr = value;
                arr[i].id = e.target.value;
                onChange(arr);
              }}
            />
          </div>
        </div>
      ))}
      <div class='flex mt-4'>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { name: "", perc: "", expiry: "", id: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const AmericanShareholderTable = ({ value, onChange, onBlur = () => {} }) => {
  return (
    <div class='flex flex-col'>
      {value.map((v, i) => (
        <div class='flex flex-col md:flex-row mb-2' style={{ backgroundColor: "rgb(222,222,222)", padding: 12, borderRadius: 2 }}>
          <p className='label flex self-center mr-2 text-2xl'>{i + 1}.</p>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Name of Owner"}
              value={v.name}
              onChange={(e) => {
                const arr = value;
                arr[i].name = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"TIN Number"}
              value={v.tin}
              startEnhancer='#'
              onChange={(e) => {
                const arr = value;
                arr[i].tin = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='number'
              key={i}
              placeholder={"Percentage"}
              value={v.perc}
              endEnhancer='%'
              onChange={(e) => {
                const arr = value;
                arr[i].perc = e.target.value;
                onChange(arr);
              }}
            />
          </div>
        </div>
      ))}
      <div class='flex mt-4'>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { name: "", tin: "", perc: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const UltimateOwnerTable = ({ value, onChange, onBlur = () => {} }) => {
  return (
    <div class='flex flex-col'>
      {value.map((v, i) => (
        <div class='flex flex-col md:flex-row mb-2' style={{ backgroundColor: "rgb(222,222,222)", padding: 12, borderRadius: 2 }}>
          <p className='label flex self-center mr-2 text-2xl'>{i + 1}.</p>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Name of Owner"}
              value={v.name}
              onChange={(e) => {
                const arr = value;
                arr[i].name = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Expiry Date"}
              value={v.expiry}
              onChange={(e) => {
                const arr = value;
                arr[i].expiry = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"ID Number"}
              value={v.id}
              onChange={(e) => {
                const arr = value;
                arr[i].id = e.target.value;
                onChange(arr);
              }}
            />
          </div>
        </div>
      ))}
      <div class='flex mt-4'>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { name: "", expiry: "", id: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const AuthorizedSignatoriesTable = ({ value, onChange, onBlur = () => {} }) => {
  return (
    <div class='flex flex-col'>
      {value.map((v, i) => (
        <div class='flex flex-col md:flex-row mb-2' style={{ backgroundColor: "rgb(222,222,222)", padding: 12, borderRadius: 2 }}>
          <p className='label flex self-center mr-2 text-2xl'>{i + 1}.</p>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Name of Owner"}
              value={v.name}
              onChange={(e) => {
                const arr = value;
                arr[i].name = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Title"}
              value={v.title}
              onChange={(e) => {
                const arr = value;
                arr[i].title = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Expiry Date"}
              value={v.expiry}
              onChange={(e) => {
                const arr = value;
                arr[i].expiry = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"ID Number"}
              value={v.id}
              onChange={(e) => {
                const arr = value;
                arr[i].id = e.target.value;
                onChange(arr);
              }}
            />
          </div>
        </div>
      ))}
      <div class='flex mt-4'>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { name: "", title: "", expiry: "", id: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const DirectorsTable = ({ value, onChange, clientId, onBlur = () => {} }) => {
  async function onUploadFile(index, file) {

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("client_id", clientId);

      const data = await upload(formData);
      console.log('ID', data);

      // this.props.onInputField(fieldTitle, data.data);
      const arr = value;
      arr[index].id_url = data.data
      onChange(arr)
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div class='flex flex-col'>
      <div className="flex flex-row justify-around">
        <p className="label">First Name</p>
        <p className="label">Father Name</p>
        <p className="label">Grandfather Name</p>
        <p className="label">Last Name</p>
        <p className="label">Picture of ID</p>
      </div>
      {value.map((v, i) => (
        <div class='flex flex-col md:flex-row mb-2 ' style={{ backgroundColor: "rgb(222,222,222)", padding: 12, borderRadius: 2 }}>
          <p className='label flex self-center mr-2 text-2xl'>{i + 1}.</p>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"First Name"}
              value={v.first_name}
              onChange={(e) => {
                const arr = value;
                arr[i].first_name = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Father Name"}
              value={v.father_name}
              onChange={(e) => {
                const arr = value;
                arr[i].father_name = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Grandfather Name"}
              value={v.grandfather_name}
              onChange={(e) => {
                const arr = value;
                arr[i].grandfather_name = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Last Name"}
              value={v.last_name}
              onChange={(e) => {
                const arr = value;
                arr[i].last_name = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div class='flex flex-1 pr-0 md:pr-2 mb-2 md:mb-0'>
            <FileInput key={i} onSelect={(file) => onUploadFile(i, file)} value={v.id_url} />
          </div>
        </div>
      ))}
      <div class='flex mt-4'>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { name: "", title: "", expiry: "", id: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};
