import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Agreement from "./tabs/Agreement";
import AccountType from "./tabs/AccountType";
import Credentials from "./tabs/Credentials";
import NationlAddress from "./tabs/NationalAddress";
import PersonalInformation from "./tabs/PersonalInformation";
import FinancialInformation from "./tabs/FinancialInformation";
import Finished from "./tabs/Finished";

import { update, updateOnboard, finishOnboard } from "../../api/client";
// import { getInvite, update, updatePassword, updateOnboard, finishOnboard, updateInvite } from "../../api/client";
import { getInvite, updatePassword, updateInvite } from "../../api/user";
import * as Theme from "../../util/theme";
import logo from "../../data/images/i46logo.png";

const STEPS = ["Type of Account", "Account Opening Agreement", "Credentials", "Finished"];
// const STEPS = ["Type of Account", "Account Opening Agreement", "Credentials", "National Address", "Primary Information", "Financial Information", "Finished"];
const STEPS_CORP = ["Type of Account", "Account Opening Agreement", "Credentials", "Finished"];
// const STEPS_CORP = ["Type of Account", "Account Opening Agreement", "Credentials", "Finished"];
const STEP_CONTEXT = [
  "Which account do you want to open?",
  "Impact Capital Company Terms and Conditions",
  "Create your password",
  // "Enter your National Address information",
  // "Please provide us with some personal information",
  // "Please provide us with some basic financial information",
];

const STEP_CONTEXT_CORP = ["Which account do you want to open?", "Impact Capital Company Terms and Conditions", "Create your password"];

class Onboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      isAgreementChecked: false,
      invite: {},
      error: null,
      
      client: {
        name: "",
        email: "",
        password: "",
        type: "individual",
        agreement: {
          isChecked: false,
        },
        credentials: {
          email: "",
          password: "",
          password2: "",
        },
        personalInformation: {},
        financialInformation: {},
      },
      user: {
        name: '',
        email: '', 
        password: ''
      },
      canNavigate: false,
    };
  }

  async componentDidMount() {
    await this.getClient();
  }

  componentDidUpdate(prevProps, prevState) {
    // Initial render
    if (!prevState.client._id && this.state.client._id) return;
  }

  async getClient() {
    const { inviteId } = this.props;
    try {
      const { data } = await getInvite(inviteId);
      this.setState({ user: data.user, client: data.client, invite: data.invite });
      console.log(data);
    } catch (e) {
      console.log(e);
      this.setState({
        error: {
          msg: "Invalid invite, please conteact Impact 46 for your correct login link.",
        },
      });
    }
  }

  onAccountTypeUpdated = async () => {
    await this.onAgreementUpdated();
  };

  onAgreementUpdated = async () => {
    try {
      const { client } = this.state;

      await update(client);

      await this.getClient();
    } catch (e) {
      console.log(e);
      this.setState({
        msg: "Error accepting agreement.",
      });
    }
  };

  onCredentialsUpdated = async () => {
    const { _id: clientId, password } = this.state.user;
    try {
      await updatePassword(clientId, password);
    } catch (e) {
      console.log(e);
      this.setState({
        msg: "Error updating account.",
      });
    }
  };

  onUpdate = async (type) => {
    try {
      const { client } = this.state;

      await updateOnboard(type, client[type], this.state.client._id);
    } catch (e) {
      console.log(e);
    }
  };

  getContent() {
    const { index, client, user } = this.state;
    // const { agreement } = client;
    console.log('HERE', this.state.client)
    if (this.state.client.type === "corporation" && index === 3) {
      return <Finished client={client} toggleNavigation={() => this.setState({ canNavigate: true })} onLogin={() => this.props.history.push("/login")} />;
    }

    switch (index) {
      case 0:
        return (
          <AccountType
            client={client}
            toggleNavigation={(canNavigate) => this.setState({ canNavigate })}
            onUpdate={(typeOfAccount) => this.setState({ client: { ...client, type: typeOfAccount } }, () => this.onAccountTypeUpdated())}
          />
        );
      case 1:
        return (
          <Agreement
            agreement={client.agreement}
            client={client}
            onCheck={(checked) => this.setState({ client: { ...client, agreement: { ...client.agreement, isAgreementChecked: checked } } }, () => this.onAgreementUpdated())}
            toggleNavigation={(canNavigate) => this.setState({ canNavigate })}
          />
        );
      case 2:
        return (
          <Credentials
            client={user}
            toggleNavigation={(canNavigate) => this.setState({ canNavigate })}
            onUpdate={(password) => this.setState({ user: { ...user, password }, client: { ...client, password } }, () => this.onCredentialsUpdated())}
          />
        );
      // case 3:
      //   return (
      //     <NationlAddress
      //       nationalAddress={nationalAddress}
      //       onUpdate={(nationalAddress) => this.setState({ client: { ...client, nationalAddress } }, () => this.onUpdate("nationalAddress"))}
      //       toggleNavigation={(canNavigate) => this.setState({ canNavigate })}
      //     />
      //   );
      // case 4:
      //   return (
      //     <PersonalInformation
      //       personalInformation={personalInformation}
      //       onUpdate={(personalInformation) => this.setState({ client: { ...client, personalInformation } }, () => this.onUpdate("personalInformation"))}
      //       toggleNavigation={(canNavigate) => this.setState({ canNavigate })}
      //     />
      //   );
      // case 5:
      //   return (
      //     <FinancialInformation
      //       financialInformation={financialInformation}
      //       onUpdate={(financialInformation) => this.setState({ client: { ...client, financialInformation } }, () => this.onUpdate("financialInformation"))}
      //       toggleNavigation={(canNavigate) => this.setState({ canNavigate })}
      //       client={client}
      //     />
      //   );

      case 3:
        return <Finished client={client} toggleNavigation={() => this.setState({ canNavigate: true })} onLogin={() => this.props.history.push("/login")} />;
      default:
        return <></>;
    }
  }

  onNext = async () => {
    const { client, index, canNavigate } = this.state;

    if (!canNavigate) return;

    console.log('INDX: ', index)
    if (index === 2) {
      await this.onCredentialsUpdated();
    }

    // if (index < 3 && client.type === "individual") {
    if (index < 3 && client.type === "individual") {
      this.setState({ index: index + 1 });
    }

    if (index < 3 && client.type === "corporation") {
      this.setState({ index: index + 1 });
    }

    if (client.type == "corporation" && index === 2) {
      this.setState({ index: index + 1 }, () => {
        this.onFinishOnboard();
      });
    }


    // if (client.type == "individual" && index === 2) {
    //   this.setState({ index: index + 1 }, () => {
    //     this.onFinishOnboard();
    //   });
    // }
    
    // COMBAK this is just here to make onboarding easier for the beginning
    // if (client.type == "individual" && index === 2) {
    //   this.setState({ index: index + 1 }, () => {
    //     this.onFinishOnboard();
    //   });
    // }

    if (index === 5) {
      this.setState({ index: index + 1 }, () => {
        this.onFinishOnboard();
      });
    }

    if (index === 3 && client.type == "corporation") {
      this.props.history.push("/login");
    }
    if (index === 3 && client.type == "individual") {
      await this.onFinishOnboard()
      this.props.history.push("/login");
    }
    // if (index === 3 && client.type == "individual") {
    //   this.props.history.push("/login");
    // }
    
    // if (index === 3 && client.type == "individual") {
    //   this.props.history.push("/login");
    // }

    if (index === 6) {
      await this.onFinishOnboard();
      this.props.history.push("/login");
    }
  };

  onBack = () => {
    const { index } = this.state;

    if (index > 0) {
      this.setState({ index: index - 1 });
    }
  };

  async onFinishOnboard() {
    try {
      const invite = await updateInvite(this.props.inviteId, "complete");
      await finishOnboard(this.state.client._id);
      console.log(invite);
      // this.props.history.push("/login");
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { client, index, canNavigate, error } = this.state;
    const steps = client?.type === "individual" ? STEPS : STEPS_CORP;
    const context = client?.type === "individual" ? STEP_CONTEXT : STEP_CONTEXT_CORP;

    return (
      <div class='min-h-screen pl-8 pr-8 lg:pl-64 lg:pr-64' style={{ paddingTop: "5vh", backgroundColor: Theme.PRIMARY }}>
        {/* <ProgressBar index={index} key={Date.now()} /> */}

        <div style={{ position: "absolute", left: 16, top: 16 }}>
          <img src={logo} width='100' />
        </div>
        <div class='flex-initial md:p-8 mb-16 mt-16 '>
          <div id='onboard-header' class='flex center flex-row justify-between items-center'>
            <div class='flex flex-col'>
              <p className="text-lg lg:text-5xl">{steps[index]}</p>
              <p style={{ fontSize: "16px", opacity: 0.5, marginLeft: 4 }}>{context[index]}</p>
            </div>
            <p className="text-sm md:text-lg">
              {index + 1}/{steps.length}
            </p>
          </div>

          <div class='card max-w-none'>
            {this.getContent()}

            {error && (
              <div class='ml-4'>
                <p style={{ color: "red" }}>{error.msg}</p>
              </div>
            )}
            <div class='justify-around flex flex-row flex-1'>
              {index !== 0 && index !== steps.length - 1 && (
                <div onClick={this.onBack} class='card flex flex-1 mt-4 ml-4' style={{ backgroundColor: "rgb(50,50,50)", cursor: "pointer" }}>
                  <button onClick={this.onBack} class='center center-vertical font-bold text-xl' style={{ color: "white" }}>
                    Back
                  </button>
                </div>
              )}
              <div onClick={this.onNext} class='card flex flex-1 mt-4 ml-4' style={{ backgroundColor: Theme.PRIMARY, cursor: "pointer" }}>
                <button onClick={this.onNext} class={"center center-vertical font-bold text-xl"} style={{ color: "white", opacity: !canNavigate ? 0.5 : 1 }}>
                  {client.type === "individual" ? (index === 6 ? "Login" : "Next") : index === 3 ? "Login" : "Next"}
                  {/* {index === 6 && client.type == 'individual' ? "Login" : "Next"} */}
                  {/* {(index === 3 && client.type == 'corporation') ? "Login" : ""} */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Onboard);
