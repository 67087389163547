import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton } from "baseui/modal";
import { KIND as ButtonKind, KIND } from "baseui/button";
import { Input } from "baseui/input";
import { forgotPassword } from "../api/auth";

const ForgotPasswordModal = ({ isOpen, onSubmit, onClose }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  async function _onSubmit() {
    if(!email.includes('@')) return;

    // onSubmit(email)
    try {
      await forgotPassword(email)
      onSubmit()
    } catch (e) {
      console.log(e);
      setError("There is no user that exists with this email, please check your email and try again");
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>Forgot Password</ModalHeader>
      <ModalBody>
        <div class='flex flex-col'>
          <div>
            <p>What is your email?</p>
            <Input value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <p style={{color: 'red' }}>{error}</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={onClose} kind={KIND.secondary}>
          Close
        </ModalButton>
        <ModalButton onClick={_onSubmit}>Submit</ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export default ForgotPasswordModal;
