import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal";
import { KIND as ButtonKind, KIND } from "baseui/button";
import { Select } from "baseui/select";

const options = [{
  id: 'rejected',
  label: 'Rejected'
}, {
  id: 'approved',
  label: 'Approved'
}]

const ReviewKYCModal = ({ client, isOpen, formType, review, onReview, onClose }) => {
  const [status, setStatus] = useState(review != null ? [{ id: review.status, label: review.status }] : []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>{client.name}'s {formType?.toUpperCase()} Status</ModalHeader>
      <ModalBody>
        <div class='flex flex-col'>
          <div>
            <p>What is the status of this KYC?</p>
            <Select
              options={options}
              onChange={({ value }) => setStatus(value)}
              value={status}
            />
          </div>
          {/* <div class='mt-4'>
            <p>Enter Subscription Amount</p>
            <Input startEnhancer='$' onChange={(e) => setAmount(e.target.value)} value={amount} />
          </div> */}
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={onClose} kind={KIND.secondary}>
          Close
        </ModalButton>
        <ModalButton onClick={() => onReview(status)}>Submit</ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export default ReviewKYCModal;
