import { Input } from "baseui/input";
import React, { Component } from "react";
import Button from "../../elements/Button";
import { find, update, review, getReview } from "../../api/form";
import * as Theme from "../../util/theme";
import { formatDate } from "../../util/datetime";
import MoonLoader from "react-spinners/MoonLoader";
import ReviewKYCModal from "./ReviewKYCModal";
import { ProgressBar, SIZE } from "baseui/progress-bar";
import ProgressCard from "./ProgressCard";
import LoaderBlock from "../loaders/LoaderBlock";
import * as FormUtil from '../../util/form';

export default class KYC extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // client: {},
      loading: true,
      isEdit: false,
      isReviewModalOpen: false,
      kycReview: null,
      fatcaReview: null,
      amlReview: null,
      kyc: {},
      fatca: {},
      aml: {},
      formToReview: "",
    };
  }

  async componentDidMount() {
    await this.init();
  }

  async init() {
    await this.getForms();
    await this.getKYCReview();
  }

  async getKYCReview() {
    const { client } = this.props;

    try {
      const { data: kycReview } = await getReview(client._id, "kyc_" + client.type);
      const { data: fatcaReview } = await getReview(client._id, "fatca_" + client.type);
      const { data: amlReview } = await getReview(client._id, "aml");

      if (!kycReview) this.props.setIsKycApproved(false);
      if (!fatcaReview) this.props.setIsFatcaApproved(false);
      if (!amlReview) this.props.setIsAmlApproved(false);

      this.setState({ kycReview, fatcaReview, amlReview }, () => {
        if (kycReview) {
          this.props.setIsKycApproved(kycReview.status.toLowerCase() === "approved");
        }
        if (fatcaReview) {
          this.props.setIsFatcaApproved(fatcaReview.status.toLowerCase() === "approved");
        }
        if (amlReview) {
          this.props.setIsAmlApproved(amlReview.status.toLowerCase() === "approved");
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.client?._id != this.props?.client?._id) {
      this.setState({ loading: true, aml: {}, kyc: {}, fatca: {}, amlReview: null, fatcaReview: null, kycReview: null });
      return this.init();
    }
  }

  async getForms() {
    const { client } = this.props;

    try {
      const { data: kyc } = await find({ client_id: client._id, type: "kyc_" + client.type });
      const { data: fatca } = await find({ client_id: client._id, type: "fatca_" + client.type });
      const { data: aml } = await find({ client_id: client._id, type: "aml" });

      await this.setState({ kyc: kyc[0], fatca: fatca[0], aml: aml[0], isFinished: false });
    } catch (e) {
      alert(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  toggleEdit = () => {
    const { isEdit } = this.state;

    if (isEdit) this.props.onEdit();

    this.setState({ isEdit: !this.state.isEdit });
  };

  onReviewKYC = async (value) => {
    if (value.length < 1) return;

    const { formToReview } = this.state;
    const { user, client } = this.props;

    let formType = "";
    // const formType = this.state.formToReview + "_" + client.type;
    let formId = "";
    if (formToReview.includes("kyc")) {
      formId = this.state.kyc._id;
      formType = formToReview + "_" + client.type;
    }

    if (formToReview.includes("fatca")) {
      formId = this.state.fatca._id;
      formType = formToReview + "_" + client.type;
    }

    if (formToReview.includes("aml")) {
      formId = this.state.aml._id;
      formType = formToReview;
    }

    try {
      await review(client._id, formId, formType, value[0].id, user._id);

      this.setState({ isReviewModalOpen: false, formToReview: "" });
    } catch (e) {
      console.log(e);
    } finally {
      await this.getKYCReview();
    }
  };

  getFieldIndexes(id) {
    const { kyc } = this.state;

    for (let i = 0; i < kyc.data.sections.length; i++) {
      for (let j = 0; j < kyc.data.sections[i].subsections.length; j++) {
        for (let k = 0; k < kyc.data.sections[i].subsections[j].fields.length; k++) {
          if (kyc.data.sections[i].subsections[j].fields[k].id == id) {
            return [i, j, k];
          }
        }
      }
    }
    return null;
  }

  /**
   * Get progress % of a section for the progress bar
   */
  getProgress(sectionIndex) {
    const { kyc } = this.state;

    if (!kyc || kyc._id == null) return;

    let total = 0;
    let completed = 0;
    // for (let i = 0; i < kyc.data.sections.length; i++) {
    for (let j = 0; j < kyc.data.sections[sectionIndex].subsections.length; j++) {
      for (let k = 0; k < kyc.data.sections[sectionIndex].subsections[j].fields.length; k++) {
        if(kyc.data.sections[sectionIndex].subsections[j].fields[k].conditionRef != null && kyc.data.sections[sectionIndex].subsections[j].fields[k].conditionRef != "") {
          // NOT NECESSARILY if it has a condition, then count it
          // total++

          const indexes = this.getFieldIndexes(kyc.data.sections[sectionIndex].subsections[j].fields[k].conditionRef)
          const conditionalField = kyc.data.sections[indexes[0]].subsections[indexes[1]].fields[indexes[2]]
          // if it has a condition, see if that condition is met

          if(conditionalField.value == kyc.data.sections[sectionIndex].subsections[j].fields[k].condition) {
            total++;
            if(kyc.data.sections[sectionIndex].subsections[j].fields[k].value != null && kyc.data.sections[sectionIndex].subsections[j].fields[k].value != "") {
              completed++
              continue;
            }
          }

          // if the conditional field has a value then count it
          // if(conditionalField.value != null && conditionalField.value != "") {
          //   completed++;
          // }
          // continue;
        }

        total++;
        if (kyc.data.sections[sectionIndex].subsections[j].fields[k].value != null && kyc.data.sections[sectionIndex].subsections[j].fields[k].value != "") {
          completed++;
        }
      }
    }
    // }
    return ((completed / total) * 100).toFixed(0);
  }

  /**
   * Get overall progress of an entire form for the overall progress bar
   */
  // getOverallProgress() {
  //   const { kyc } = this.state;
  //   if (!kyc || kyc._id == null) return;

  //   let total = 0;
  //   let completed = 0;
  //   for (let i = 0; i < kyc.data.sections.length; i++) {
  //     for (let j = 0; j < kyc.data.sections[i].subsections.length; j++) {
  //       for (let k = 0; k < kyc.data.sections[i].subsections[j].fields.length; k++) {
          
  //         // Look for if the field is conditional and should be counted
  //         if(kyc.data.sections[i].subsections[j].fields[k].conditionRef != null && kyc.data.sections[i].subsections[j].fields[k].conditionRef != "") {
  //           // if it has a condition, then count it
  //           total++
  //           const indexes = this.getFieldIndexes(kyc.data.sections[i].subsections[j].fields[k].conditionRef)
  //           const conditionalField = kyc.data.sections[indexes[0]].subsections[indexes[1]].fields[indexes[2]]
            
  //           // if the conditional field has a value then count it
  //           if(conditionalField.value != null && conditionalField.value != "") {
  //             completed++;
  //           }
  //           continue;
  //         }

  //         total++;
  //         if (kyc.data.sections[i].subsections[j].fields[k].value != null && kyc.data.sections[i].subsections[j].fields[k].value != "") {
  //           completed++;
  //         }
  //       }
  //     }
  //   }

  //   const perc = ((completed / total) * 100).toFixed(0);
  //   localStorage.setItem('kyc_progress', perc.toString())
  //   return perc;
  // }

  render() {
    const { kyc, fatca, aml, kycReview, fatcaReview, amlReview, loading } = this.state;
    const { user, client } = this.props;
    // const completion = this.getOverallProgress();

    return (
      <>
        <div class='relative'>
          <div class='flex flex-col '>
            
            <ProgressCard
              title='KYC'
              form={kyc}
              review={kycReview}
              isAdmin={user.is == "admin"}
              onOpenReview={() => this.setState({ isReviewModalOpen: true, formToReview: "kyc" })}
              toForm={this.props.toKyc}
            />
            <ProgressCard
              title='FATCA'
              form={fatca}
              review={fatcaReview}
              isAdmin={user.is == "admin"}
              onOpenReview={() => this.setState({ isReviewModalOpen: true, formToReview: "fatca" })}
              toForm={this.props.toFatca}
            />
            {client.type === "corporation" && (
              <ProgressCard
                title='AML'
                form={aml}
                review={amlReview}
                isAdmin={user.is == "admin"}
                onOpenReview={() => this.setState({ isReviewModalOpen: true, formToReview: "aml" })}
                toForm={this.props.toAml}
              />
            )}
          </div>

          <ReviewKYCModal
            formType={this.state.formToReview}
            client={this.props.client}
            isOpen={this.state.isReviewModalOpen}
            onClose={() => this.setState({ isReviewModalOpen: false, formToReview: "" })}
            onReview={this.onReviewKYC}
            review={review}
          />
        </div>
      </>
    );
  }
}

const progressBarOverride = {
  BarContainer: {
    style: ({}) => {
      return {
        marginLeft: 0,
      };
    },
  },
  BarProgress: {
    style: ({}) => {
      return {
        backgroundColor: Theme.PRIMARY,
      };
    },
  },
};
