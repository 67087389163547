import * as React from "react";
import { Button } from "baseui/button";
import { ButtonGroup } from "baseui/button-group";

export default function RadioButton({ value = "", options = [], onSelect }) {
  const firstUpdate = React.useRef(true);

  let initialValue = options.findIndex((o) => o == value)
  if (initialValue >= 0) initialValue = [initialValue];

  const [selected, setSelected] = React.useState(initialValue);

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    onSelect(options[selected[0]]);
  }, [selected]);

  return (
    <ButtonGroup
      mode='checkbox'
      selected={selected}
      onClick={(event, index) => {
        setSelected([index]);
        // if (!selected.includes(index)) {
        //   setSelected([...selected, index]);
        // } else {
        //   setSelected(selected.filter((value) => value !== index));
        // }
      }}
    >
      {options.map((o) => (
        <Button>{o}</Button>
      ))}
    </ButtonGroup>
  );
}
