import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Checkbox } from "baseui/checkbox";
import file from "../forms/agreement.pdf";
import Button from "../../../elements/Button";


console.log(pdfjs.version);
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js`;

const AGREEMENT = "https://jeffery-saudi.s3.eu-central-1.amazonaws.com/agreement.pdf#page1";

// const NUM_PAGES = 23;
const pages = [];
for (let i = 0; i < 23; i++) {
  pages.push(0);
}

const Agreement = ({ client, onCheck, toggleNavigation }) => {
  const [isChecked, setIsChecked] = useState(client?.agreement?.isAgreementChecked);

  useEffect(() => {
    if (client?.agreement?.isAgreementChecked) {
      return toggleNavigation(true);
    }
    return toggleNavigation(false);
  }, [client?.agreement?.isAgreementChecked]);

  return (
    <div id='agreement'>
      <p class='faded mb-2'>Please scroll all the way to the bottom to confirm your account</p>
      <div className='pb-8 text-lg font-bold' style={{height: window.innerWidth < 500 ? 48 :400}}>
        {window.innerWidth < 500 ?
        <div className="flex justify-center items-center mt-32" >
          <Button text='View T&C' type='tertiary' onClick={() => window.open(AGREEMENT, '_blank')}/>
        </div>  
        :
        <object data={AGREEMENT} type='application/pdf' height='500px' width='800px'>
        </object>
      }
      </div>

      <div class='pt-8 pb-8 pl-2 mt-20'>
        <Checkbox checked={client?.agreement?.isAgreementChecked} onChange={(e) => onCheck(e.target.checked)}>
          The client hereby requests Impact Capital to open an account and provide investment services in accordance with the terms and conditions of this Agreement, and any amendments thereto relating to such accounts and services. The Agreement will be forcible once it is signed by the Client and the Company.
        </Checkbox>
      </div>
    </div>
  );
};

export default Agreement;

// {/* <p>Pleaes click this link to view our Account Opening Agreement. After you have read it, please accept the terms and conditions.</p> */}
// {/* <a target="_blank" href={AGREEMENT} rel='noreferrer'>View Agreement</a> */}
// {/* <Document file={file} style={{ overflow: "scroll" }} onLoadSuccess={(pdf) => console.log("PDF", pdf)}>
// {pages.map((v, i) => (
//   <Page pageNumber={i + 1} key={i}/>
//   ))}
// </Document> */}