import { http } from "./api"

const USER = "/user"
const INVITE = USER + "/invite"

export function create(user) {
  return http.post(USER, user)
}

export function get(userId) {
  return http.get(USER + "/" + userId)
}

export function updatePassword(userId, password) {
  const data = {
    password,
  }
  return http.put(USER + "/" + userId, data)
}

export function invite(user) {
    const data = {
        user
    }
    return http.post(INVITE, data)
}

export function getInvite(inviteId) {
  return http.get(INVITE + "/" + inviteId)
}

export function updateInvite(inviteId, status) {
  const data = {
    status,
  }
  return http.put(INVITE + '/' + inviteId, data)
}
