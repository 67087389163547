import React, { Component } from "react";
import { Input } from "baseui/input";
import Button from "../../elements/Button";
import { me } from "../../api/bank-account";
import { withRouter } from "react-router-dom";
import { withContext } from "../../context/Auth";
import * as Theme from "../../util/theme";
import { formatDate } from "../../util/datetime";
import MoonLoader from "react-spinners/MoonLoader";
import EmptyState from "../../elements/EmptyState";
import LoaderBlock from "../loaders/LoaderBlock";

class BankAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // client: {},
      loading: true,
      bankAccounts: [],
    };
  }

  componentDidMount() {
    this.getBankAccounts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.client?._id != this.props?.client?._id) {
      this.setState({ loading: true });
      return this.getBankAccounts();
    }
  }

  async getBankAccounts() {
    const { activeClient } = this.props.context;

    try {
      const { data: bankAccounts } = await me.get(activeClient._id);
      console.log(bankAccounts);

      this.setState({ bankAccounts });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading, bankAccounts } = this.state;

    if (loading) {
      return LoaderState;
    }

    if (!loading && bankAccounts.length === 0) {
      return <div class='flex justify-center mt-8'>
          <EmptyState heading='' text='' illustration='creditcard' buttonText='Add Bank Account' onClick={() => this.props.toForm("bank-account")} />
        </div>
    }

    return (
      <div >
        <div class='flex justify-between items-end flex-row flex-1 mt-8'>
          <p class='font-lg font-bold' style={{ color: "rgb(140,140,140)" }}>
            Bank Accounts
          </p>
          <Button text='Add Account' type='tertiary' onClick={this.props.toForm} />
        </div>

        {bankAccounts.map((b, i) => (
          <div class='card mt-4 p-8 max-w-none' style={{ position: "relative" }}>
            {/* <p class='text-xl font-bold mb-4'>{subscriptionPlan?.name}</p> */}
            <div class='flex flex-1 flex-row mb-8'>
              <div class='flex-1'>
                <p class='label'>Name on Account</p>
                {/* {this.inputFactory("g_na_city")} */}
                <p class='value'>{b.name_on_account}</p>
              </div>
              <div class='flex-1'>
                <p class='label'>IBAN Number</p>
                <p class='value'>#{b.iban}</p>
                {/* <p class='label'>Account Number</p> */}
                {/* {this.inputFactory("g_na_country")} */}
                {/* <p class='value'>#{b.account_number}</p> */}
              </div>
            </div>

            <div class='flex flex-1 flex-row '>
              <div class='flex-1'>
                <p class='label'>Bank Name</p>
                <p class='value'>{b.bank}</p>
                {/* {this.inputFactory("Riyadh", "bank")} */}
              </div>
              <div class='flex-1'>
                {/* <p class='label'>IBAN Number</p> */}
                {/* <p class='value'>#{b.iban.substring(b.iban.length - 4, b.iban.length)}</p> */}
                {/* {this.inputFactory("123 Abc. St", "date_of_opening")} */}
              </div>
            </div>

            <div style={{ position: "absolute", right: 16, bottom: 12, cursor: "grab" }} onClick={this.toggleEdit}>
              {/* <h1 class='faded text-xs' style={{ textDecoration: "underline" }}>
                 Edit Profile
               </h1> */}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default withContext(BankAccount);

const LoaderState = (
  <div>
    <div class='flex justify-between items-end flex-row flex-1 mt-8'>
      <p class='font-lg font-bold' style={{ color: "rgb(140,140,140)" }}>
        <LoaderBlock height={32} width={200} marginBottom={8} />
      </p>
      <LoaderBlock height={40} width={120} marginBottom={8} />
    </div>

    <div class='card mt-4 p-8' style={{ position: "relative" }}>
      <div class='flex flex-1 flex-row mb-8'>
        <div class='flex-1'>
          <LoaderBlock height={16} width={100} marginBottom={8} />
          <LoaderBlock height={20} width={120} marginBottom={8} />
        </div>
        <div class='flex-1'>
          <LoaderBlock height={16} width={100} marginBottom={8} />
          <LoaderBlock height={20} width={120} marginBottom={8} />
        </div>
      </div>

      <div class='flex flex-1 flex-row '>
        <div class='flex-1'>
          <LoaderBlock height={16} width={100} marginBottom={8} />
          <LoaderBlock height={20} width={120} marginBottom={8} />
        </div>
        <div class='flex-1'>
          <LoaderBlock height={16} width={100} marginBottom={8} />
          <LoaderBlock height={20} width={120} marginBottom={8} />
        </div>
      </div>

      <div style={{ position: "absolute", right: 16, bottom: 12, cursor: "grab" }}>
        {/* <h1 class='faded text-xs' style={{ textDecoration: "underline" }}>
                 Edit Profile
               </h1> */}
      </div>
    </div>
  </div>
);
