import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { withContext } from "../context/Auth"
import { Input } from "baseui/input"
import { login, forgotPassword } from "../api/auth"
import * as Theme from "../util/theme"
import MoonLoader from "react-spinners/MoonLoader"
import logo from "../data/images/i46logo.png"
import ForgotPasswordModal from "./ForgotPasswordModal"

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      password: "",
      loading: false,
      error: null,
      isForgotPasswordOpen: false,
    }
  }

  componentDidMount() {
    if (this.props.context.isAuthenticated) {
      this.props.history.push("/dashboard")
    }
  }

  onSubmit = async () => {
    this.setState({ loading: true })

    if (!this.state.email.includes("@")) {
      return this.setState({ error: { message: "Not a valid email address" } })
    }

    try {
      const { data: user } = await login(this.state.email.toLowerCase(), this.state.password)
      console.log('USER', user)
      if (!user) throw "No user"

      await this.props.context.setUser(user)
      setTimeout(() => {
        this.props.history.push("/dashboard")
      }, 250)
    } catch (e) {
      console.log(e.message)
      this.setState({ loading: false, error: { message: "No user with this email and password" } })
    } finally {
      this.setState({ loading: false })
    }
  }

  onForgotPassword = async (email) => {
    this.setState({ isForgotPasswordOpen: false })
  }

  render() {
    const { loading, error, isForgotPasswordOpen } = this.state

    return (
      <div class="min-h-screen p-8 pt-32 md:p-48 md:pt-40" style={{ backgroundColor: Theme.PRIMARY }}>
        <div style={{ position: "absolute", left: 16, top: 16 }}>
          <img src={logo} width="100" />
        </div>
        <p class="text-5xl font-bold" style={{ color: "white", opacity: 0.7 }}>
          Welcome to Jeffery
        </p>
        <p class="text-lg font-bold mb-4" style={{ color: "white", opacity: 0.7 }}>
          Your virtual account manager
        </p>
        <div class="flex card flex-col">
          <div class="mb-4">
            <p class="label mb-2">Email</p>
            <Input onChange={(e) => this.setState({ email: e.target.value, error: null })} class="value" placeholder="Email" />
          </div>

          <div class="mb-4">
            <p class="label mb-2">Password</p>
            <Input type="password" onChange={(e) => this.setState({ password: e.target.value, error: null })} class="value" placeholder="°°°°" />
          </div>

          <div class="flex flex-row items-center mt-4">
            <p className="ml-2 pr-8" style={{ textDecoration: "underline", opacity: 0.5, cursor: "pointer" }} onClick={() => this.setState({ isForgotPasswordOpen: true })}>
              Forgot password?
            </p>
            <button class="submit flex-1 faded mr-4" onClick={this.onSubmit}>
              Login
            </button>
            {loading && <MoonLoader size="32" width="40px" color={Theme.PRIMARY} />}
          </div>
          {error && <p style={{ color: "red" }}>{error.message}</p>}
        </div>

        <ForgotPasswordModal isOpen={isForgotPasswordOpen} onClose={() => this.setState({ isForgotPasswordOpen: false })} onSubmit={this.onForgotPassword} />
      </div>
    )
  }
}

export default withContext(withRouter(Login))
