import { http, serialize } from './api';

const SUBSCRIPTION_PLAN = '/subscription-plan'
const MY_SUBSCRIPTION_PLAN = SUBSCRIPTION_PLAN + '/me'

export function list() {
    return http.get(SUBSCRIPTION_PLAN)
}

export function get(planId) {
    return http.get(SUBSCRIPTION_PLAN + '/' + planId)
}

export function subscribe(planId, amount, bankAccountId, clientId) {
    const d = {
        planId,
        amount,
        bankAccountId,
        clientId
    }
    console.log(d)
    return http.post(SUBSCRIPTION_PLAN + '/subscribe', d)
}

export function updateSubscription(subscriptionId, amount, bankAccountId) {
    const d = {
        amount,
        bankAccountId
    }
    console.log(d)
    return http.put(SUBSCRIPTION_PLAN + '/subscribe/' + subscriptionId, d)
}

export const me = {
    get: function(clientId) {
        return http.get(MY_SUBSCRIPTION_PLAN + '/' + clientId)
    }
}