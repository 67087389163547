import React from 'react';
import {  Link  } from "react-router-dom";
import cards from "../data/images/cards.png";
import documents from "../data/images/documents.png";
import done from "../data/images/done.png";
// import error = from "../data/images/error.png";
import search from "../data/images/search.png";
import Button from './Button';

function getIllustration(img){
  	switch (img) { 
	    case 'creditcard':
	    	return cards
	    case 'document':
	    	return documents
	    case 'success':
	    	return done
	    // case 'error':
	    	// return require("../data/images/error.png");
	    case 'search':
	    	return search

	    default:
	    	return null;
	}
}



const EmptyState = props => {
  return (
     	<div className="pt-2 mb-2">
          <div className="flex flex-col justify-center items-center mt-2">
            <img className="" src={getIllustration(props.illustration)} />
            <h2 className="font-bold mb-6">{props.heading}</h2>
            <p className=" w-80">{props.text}</p>
            {(props.buttonText != "" && props.onClick === undefined) ?
            	<Link to={props.url}> 
	              <button>{props.buttonText}</button>
	            </Link>
        	: ''}

        	{(props.onClick != null) ?
	             <Button type='tertiary' text={props.buttonText} onClick={() => props.onClick()} />
        	: ''}
            
          </div>
        </div>
  );
};  


export default EmptyState; 
