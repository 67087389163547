import React, { useState, useEffect } from "react";
import { Input } from "baseui/input";

const Credentials = ({ client: _client, onUpdate, toggleNavigation }) => {
  const [client, setClient] = useState(_client);
  const [password, setPassword] = useState(client.password);
  const [password2, setPassword2] = useState(client.password);
  console.log('clienttt', client)
  function updateClient() {
    console.log(client);
    if (password.length < 4 || password !== password2) alert("Passwords mismatch");
  }

  useEffect(() => {
    // if("^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$") {

    // }

    if(password.length > 0 && password2.length > 0 && password === password2) {
      onUpdate(password)
      setTimeout(() => {  
        return toggleNavigation(true)
      }, 1000)
    }
    return toggleNavigation(false)
  }, [password, password2])

  return (
    <div class='flex-initial'>
      <div class='mb-4 p-2'>
        <p class='mb-2 label' style={{ color: "rgb(140,140,140)" }}>
          Email
        </p>
        <Input disabled placeholder='client@gmail.com' value={client.email} onChange={(e) => setClient({ ...client, email: e.target.value })} />
      </div>
      <div class='mb-4 p-2'>
        <p class='mb-2 label' style={{ color: "rgb(140,140,140)" }}>
          Password
        </p>
        <Input type='password' placeholder='********' value={password} onChange={(e) => setPassword(e.target.value)} />
      </div>
      <div class='mb-4 p-2'>
        <p class='mb-2 label' style={{ color: "rgb(140,140,140)" }}>
          Confirm Password
        </p>
        <Input type='password' placeholder='********' value={password2} onChange={(e) => setPassword2(e.target.value)} />
      </div>
      {password.length > 0 && password2.length > 0 && password !== password2 && <p style={{ color: "red" }}>The passwords dont match!</p>}
    </div>
  );
};

export default Credentials;
