import React, { Component } from "react";
import { find, update } from "../../api/form";
import SideNavForm from "../SideNavForm";
import * as Theme from "../../util/theme";
import FileInput from "../../inputs/FileInput";
import List from "../../inputs/List";
import DatePicker from "../../inputs/DatePicker";
import RadioButton from "../../inputs/RadioButton";
import { Input } from "baseui/input";
import Select from "../../inputs/Select";
import { TaxResidenceTable } from "../../inputs/Tables";

import { getFieldIndexes, getFieldIndexesByTitle } from '../../util/form';
import { withContext } from "../../context/Auth";

class AML extends Component {
  constructor(props) {
    super(props);

    this.state = {
      forms: [],
      form: {},
    };
  }

  componentDidMount() {
    this.getForms();
  }

  async getForms() {
    const { activeClient: client } = this.props.context;
    const { clientId } = this.props;

    try {
      const { data: forms } = await find({ client_id: clientId, type: "aml" });
      console.log(forms);

      await this.setState({ forms, form: forms[0], isFinished: false });
    } catch (e) {
      alert(e);
    }
  }

  saveForm = async () => {
    const { form } = this.state;
    if(this.props.context?.user?.email?.includes('compliance')) return;
    await this.setState({ loading: true });

    try {
      await update(form._id, form);
    } catch (e) {
      alert(e);
    } finally {
      await this.setState({ loading: false });
    }
  };

  onInputField(title, value, callback=()=>{}) {
    const { form } = this.state;
    console.log(value);
    const indexes = getFieldIndexesByTitle(title, form)

    form.data.sections[indexes[0]].subsections[indexes[1]].fields[indexes[2]].value = value;

    this.setState({ form }, () => callback())
  }

  getInputType(field) {
    switch (field.type) {
      case "text":
      case "phone":
      case "email":
        return <Input onBlur={this.saveForm} key={field.title} placeholder={field.title} value={field.value} onChange={(e) => this.onInputField(field.title, e.target.value)} />;

      case "number":
        return <Input onBlur={this.saveForm} type='number' key={field.title} placeholder={field.title} value={field.value} onChange={(e) => this.onInputField(field.title, e.target.value)} />;

      case "dropdown":
        return <Select onBlur={this.saveForm} key={field.id} multi={field.multi} value={field.value} placeholder={field.title} options={field.options} onSelect={(value) => this.onInputField(field.title, value)} />;
      // return <Select key={field.id} multi={field.multi} value={field.value} placeholder={field.title} options={field.options} onSelect={(value) => (field.value = value)} />;
      case "radio":
        return <RadioButton onBlur={this.saveForm} key={field.title} value={field.value} options={field.options} onSelect={(value) => this.onInputField(field.title, value, this.saveForm)} />;
      case "date":
        let date = new Date();
        if (Date.parse(field.value)) {
          date = new Date(field.value);
        }

        return <DatePicker key={field.id} value={date} onSelect={(value) => this.onInputField(field.title, value, this.saveForm)} />;
      case "signature":
        return (
          <input
            key={field.id}
            value={field.value}
            onChange={(e) => this.onInputField(field.title, e.target.value)}
            placeholder='Signature'
            onBlur={this.saveForm}
            style={{ fontWeight: "bold", width: "80%", height: 64, backgroundColor: "rgb(240,240,240)", fontFamily: "cursive", fontSize: "1.5rem", paddingLeft: 12, boxShadow: "none" }}
          />
        ); //<Input $style={{fontFamily: 'cursive', color:'blue'}} placeholder={field.title} value={field.value} onChange={(e) => this.onInputField(field.title, e.target.value)} />;

      case "table_taxresidence":
        return <TaxResidenceTable key={field.title} value={field.value} onChange={(value) => this.onInputField(field.title, value)} onBlur={this.saveForm} />

      case "uploadimage":
        return <FileInput key={field.title} onSelect={(file) => this.onUploadFile(field.title, file)} value={field.value} />;

      case "list":
        return <List onBlur={this.saveForm} key={field.title} value={field.value} onChange={(value) => this.onInputField(field.title, value)} />;

      default:
        return <Input onBlur={this.saveForm} key={field.title} placeholder={field.title} value={field.value} onChange={(e) => this.onInputField(field.title, e.target.value)} />;
    }
  }

  canFieldBeRendered = (id, valueToMatch) => {
    const { form } = this.state;
    const indexes = getFieldIndexes(id, form)
    if(!indexes) return true;
    if(!valueToMatch) return false;

    const valueToCheck = form.data.sections[indexes[0]].subsections[indexes[1]].fields[indexes[2]].value;
    console.log('value to check', valueToCheck)
    console.log('value to match', valueToMatch)
    // if fields match, it CANNOT be rendered
    return valueToCheck == valueToMatch
  }

  render() {
    const { forms, form } = this.state;
    console.log(this.props)
    const { activeClient: client } = this.props.context;

    if (!form._id) {
      return (
        <div class='flex flex-row' style={{ backgroundColor: Theme.BACKGROUND }}>
          <SideNavForm title={"Anti-Money Laundering and Counter Terrorism Form"} formId={""} activeIndex={0} sections={[]} onSelect={this.onSelectNavItem} onBack={() => (window.location.pathname = "/dashboard")} />

          <div class='container pt-32 pl-32 pr-32 min-h-screen' style={{ backgroundColor: "rgb(220,220,220)" }}></div>
        </div>
      );
    }

    const subsection = form.data.sections[0].subsections[0];

    return (
      <div class='flex flex-row' style={{ backgroundColor: Theme.BACKGROUND }}>
        <SideNavForm title={"Anti-Money Laundering and Counter Terrorism Form"} formId={""} activeIndex={0} sections={[]} onSelect={this.onSelectNavItem} onBack={() => (window.location.pathname = "/dashboard")} />

        <div class='container pt-32 pl-32 pr-32 min-h-screen' style={{ backgroundColor: "rgb(220,220,220)" }}>
          <div class='flex-initial p-8 mt-4 mb-16 card'>
            {subsection.fields.map((field, i) => {
              if (client?.type === "personal" && field.only === "corp") return <></>;
              if (field.conditionRef) {
                const canBeRendered = this.canFieldBeRendered(field.conditionRef, field.condition);
                console.log(field.title, field.conditionRef, canBeRendered);
                if (!canBeRendered) return <></>;
              }

              if (!field.group) {
                return (
                  <div class='mb-4 p-2' key={i}>
                    <p class='mb-2 label' style={{ color: "rgb(140,140,140)" }}>
                      {field.title}
                    </p>
                    {/* <Input placeholder={field.title} /> */}
                    {this.getInputType(field)}
                  </div>
                );
              }

              // catch the second one in the group
              if (subsection.fields[i - 1]?.group == field.group) return;

              if (field.group) {
                return (
                  <div class='flex flex-row justify-between flex-1 items-stretch mb-4 p-2'>
                    <div class='flex flex-col flex-1 mr-8'>
                      <p class='mb-2 label' style={{ color: "rgb(140,140,140)" }}>
                        {field.title}
                      </p>
                      {this.getInputType(field)}
                    </div>
                    <div class='flex flex-col flex-1 mr-4'>
                      <p class='mb-2 label' style={{ color: "rgb(140,140,140)" }}>
                        {subsection.fields[i + 1].title}
                      </p>
                      {this.getInputType(subsection.fields[i + 1])}
                    </div>
                  </div>
                );
              }
              return <></>;
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default withContext(AML);
