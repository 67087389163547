import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Theme from "../util/theme";
import { getFieldIndexes } from "../util/form";

const isMobile = window.innerWidth < 960;

class SideNavForm extends Component {
  static propTypes = {
    title: PropTypes.string,
    formId: PropTypes.string,
    sections: PropTypes.arrayOf({
      title: PropTypes.string,
    }),
    activeIndex: PropTypes.number,
    onSelect: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      isMobileNavOpen: false,
    };
  }

  onSelect(index) {
    this.props.onSelect(index);
  }

  hasOustandingFields(sectionIndex) {
    const { sections } = this.props;

    function getFieldIndexesFromSection(id) {
      for (let j = 0; j < sections[sectionIndex].subsections.length; j++) {
        for (let k = 0; k < sections[sectionIndex].subsections[j].fields.length; k++) {
          if (sections[sectionIndex].subsections[j].fields[k].id == id) {
            return [sectionIndex, j, k];
          }
        }
      }
    }

    for(let i = 0; i < sections[sectionIndex].subsections.length; i++) {
      for(let j = 0; j < sections[sectionIndex].subsections[i].fields.length; j++) {
        if (sections[sectionIndex].subsections[i].fields[j].conditionRef != null && sections[sectionIndex].subsections[i].fields[j].conditionRef != "") {
          const indexes = getFieldIndexesFromSection(sections[sectionIndex].subsections[i].fields[j].conditionRef);
          const conditionalField = sections[sectionIndex].subsections[indexes[1]].fields[indexes[2]];

          // if it has a condition, see if that condition is met
          if (conditionalField.value == sections[sectionIndex].subsections[i].fields[j].condition) {
            // total++;
            if (sections[sectionIndex].subsections[i].fields[j].value == null && sections[sectionIndex].subsections[i].fields[j].value == "") {
              // completed++;
              // continue;
              return true
            }
            continue;
          }
          continue;
        }

        if (sections[sectionIndex]?.subsections[i]?.fields[j]?.value == null || sections[sectionIndex]?.subsections[i]?.fields[j]?.value == "") {
          // completed++;
          // continue;
          return true;
        }
      }
    }
  }

  render() {
    // const { items } = this.state;
    const { title, subtitle, sections, backTo } = this.props;
    const { activeIndex, onBack } = this.props;

    if (isMobile)
      return (
        <div className='flex flex-row items-center justify-between pl-4' style={{ backgroundColor: Theme.PRIMARY, height: 64 }}>
          <a href={backTo ? backTo : "/dashboard"} className='flex flex-row items-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='32'
              viewBox='0 0 24 24'
              fill='none'
              stroke='white'
              stroke-width='2'
              stroke-linecap='round'
              stroke-linejoin='round'
              class='feather feather-arrow-left-circle'
            >
              <circle cx='12' cy='12' r='10'></circle>
              <polyline points='12 8 8 12 12 16'></polyline>
              <line x1='16' y1='12' x2='8' y2='12'></line>
            </svg>
            <p className='text-md font-bold ml-2' style={{ color: "white" }}>
              {subtitle}
            </p>
          </a>

          {sections?.length > 0 && (
            <div className='mr-8' onClick={() => this.setState({ isMobileNavOpen: !this.state.isMobileNavOpen })}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='white'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                class='feather feather-menu'
              >
                <line x1='3' y1='12' x2='21' y2='12'></line>
                <line x1='3' y1='6' x2='21' y2='6'></line>
                <line x1='3' y1='18' x2='21' y2='18'></line>
              </svg>
              {this.state.isMobileNavOpen && (
                <div className='absolute right-0 top-16 h-300 w-80 pt-4' style={{ backgroundColor: Theme.TERTIARY, boxShadow: "-2px 4px 12px rgb(140,140,140)", zIndex: 1 }}>
                  {sections?.map((item, i) => (
                    <button
                      onClick={() => this.onSelect(i)}
                      class='block py-2.5 my-2 px-4  transition duration-200 hover:bg-green-500 hover:text-white w-full text-left pl-4'
                      style={activeIndex === i ? { backgroundColor: Theme.SECONDARY } : null}
                      key={i}
                    >
                      <div className="flex flex-row items-center justify-between">{item.title} {this.hasOustandingFields(i) ? warningsvg : checksvg}</div>
                    </button>
                  ))}
                </div>
              )}
            </div>
          )}
          {/* <a href={backTo ? backTo : '/dashboard'} class='text-white flex items-center space-x-2 px-4'>Go Back</a> */}
        </div>
      );

    return (
      <div class='min-h-screen md:flex' style={{ backgroundColor: Theme.PRIMARY }}>
        <div class='bg-gray-800 text-gray-100 flex justify-between md:hidden'>
          <a href={backTo ? backTo : "/dashboard"} class='block p-4 text-white font-bold'>
            Jeffery
          </a>

          <button class='mobile-menu-button p-4 focus:outline-none focus:bg-gray-700'>
            <svg class='h-5 w-5' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 6h16M4 12h16M4 18h16' />
            </svg>
          </button>
        </div>

        <div class='sidebar text-blue-100 w-58 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out pl-0 pr-0'>
          <a href={backTo ? backTo : "/dashboard"} class='text-white flex items-center space-x-2 px-4'>
            {/* <svg class='w-8 h-8' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='2'
                d='M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z'
              />
            </svg> */}
            <div class='flex-col'>
              <h1 class='text-md font-bold' style={{ opacity: 0.5, padding: 0, margin: 0 }}>
                {subtitle}
              </h1>
              <h1 class='text-2xl font-extrabold' style={{ padding: 0, margin: 0 }}>
                {title}
              </h1>
            </div>
          </a>

          <nav>
            {sections?.map((item, i) => (
              <button
                onClick={() => this.onSelect(i)}
                class='block py-2.5 my-2 pr-2  transition duration-200 hover:bg-green-500 hover:text-white w-full text-left pl-4'
                style={activeIndex === i ? { backgroundColor: Theme.SECONDARY } : null}
                key={i}
              >
                <div className="flex flex-row items-center justify-between">{item.title} {this.hasOustandingFields(i) ? warningsvg : checksvg}</div>
              </button>
            ))}
          </nav>
        </div>
        {onBack && (
          <div class='cursor-pointer' style={{ position: "fixed", left: 16, bottom: 32, color: "white" }} onClick={onBack}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='48'
              height='48'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              stroke-width='2'
              stroke-linecap='round'
              stroke-linejoin='round'
              class='feather feather-arrow-left-circle'
            >
              <circle cx='12' cy='12' r='10'></circle>
              <polyline points='12 8 8 12 12 16'></polyline>
              <line x1='16' y1='12' x2='8' y2='12'></line>
            </svg>
          </div>
        )}
      </div>
    );
  }
}

export default SideNavForm;

const warningsvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='#c7a500'
    stroke-width='2'
    stroke-linecap='round'
    stroke-linejoin='round'
    class='feather feather-alert-triangle'
  >
    <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
    <line x1='12' y1='9' x2='12' y2='13'></line>
    <line x1='12' y1='17' x2='12.01' y2='17'></line>
  </svg>
);

const checksvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='#43a047'
    stroke-width='2'
    stroke-linecap='round'
    stroke-linejoin='round'
    class='feather feather-check-circle'
  >
    <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path>
    <polyline points='22 4 12 14.01 9 11.01'></polyline>
  </svg>
);