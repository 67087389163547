import React, { useEffect } from 'react';
import EmptyState from '../../../elements/EmptyState';

const Finished = ({ onLogin, toggleNavigation}) => {

    useEffect(() => {
        toggleNavigation(true)
    }, [])

    return(
        <div class='flex justify-center'>
            <div>
                <EmptyState heading='Your account with IMPACT 46 has created successfully!' text='Your account is setup. You will receive updates via email. You may close this app or you can visit your dashboard by logging in.' illustration='success'/>
            </div>
        </div>
    )
}

export default Finished