import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withContext } from "../../context/Auth";
import { Table } from "baseui/table";
import * as Theme from "../../util/theme";
import UITable from "./Table";
import Header from "../Header";
import SideNav from "../SideNav";
import ReminderModal from "./ReminderModal";
import { remind } from "../../api/client";

class MasterView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientToRemind: null,
    };
  }

  componentDidMount() {}

  onRemind = async () => {
    const { clientToRemind } = this.state;

    try {
      await remind(clientToRemind._id);
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ clientToRemind: null })
    }
  };

  render() {
    const { clientToRemind } = this.state;
    const { user } = this.props.context;
    return (
      <div class='flex flex-row' style={{ backgroundColor: Theme.BACKGROUND }}>
        {user.is === "admin" && <SideNav user={user} clients={[]} activeClient={{}} onSelect={() => {}} />}
        <div className='flex flex-1 flex-col'>
          <Header noSearch={true} />

          <div class='flex flex-col  justify-center pt-16'>
            <p class='pl-32 mb-4 text-4xl font-bold'>Client Overview</p>
            <div className='pl-16 pr-16'>
              <UITable onRemindClient={(c) => this.setState({ clientToRemind: c })} />
            </div>
          </div>
        </div>

        <ReminderModal isOpen={clientToRemind != null} onSend={this.onRemind} client={clientToRemind} onClose={() => this.setState({ isReminderModalOpen: false, clientToRemind: null })} />
      </div>
    );
  }
}

export default withContext(withRouter(MasterView));
