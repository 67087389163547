import React from "react";

const SubNav = ({ onAddUser, onDownload }) => {
  return (
    <div id='subnav'>
      <div class='flex flex-row'>
        {/* <button id='circle-button' onClick={onDownload}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='32'
            height='32'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            class='feather feather-download center'
          >
            <path d='M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4'></path>
            <polyline points='7 10 12 15 17 10'></polyline>
            <line x1='12' y1='15' x2='12' y2='3'></line>
          </svg>
        </button> */}
        <button id='circle-button' onClick={onAddUser}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='32'
            height='32'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            class='feather feather-user-plus center'
          >
            <path d='M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2'></path>
            <circle cx='8.5' cy='7' r='4'></circle>
            <line x1='20' y1='8' x2='20' y2='14'></line>
            <line x1='23' y1='11' x2='17' y2='11'></line>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default SubNav;
