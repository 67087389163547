import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal";
import { KIND as ButtonKind, KIND } from "baseui/button";

const Avatar = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'))
  console.log(user)
  function logout() {
      console.log(document.cookie)
      localStorage.removeItem('user')

      window.location = '/login'
  }

  return (
    <div id='avatar'>
      <button id='circle-button'  onClick={() => setIsOpen(!isOpen)}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='32'
          height='32'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          class='feather feather-user center'
        >
          <path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2'></path>
          <circle cx='12' cy='7' r='4'></circle>
        </svg>
      </button>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalHeader>{user?.name}</ModalHeader>
        <ModalBody>
          <h1>{user?.email}</h1>
        </ModalBody>
        <ModalFooter>
          <ModalButton onClick={logout} kind={KIND.secondary}>Logout</ModalButton>
          <ModalButton onClick={() => setIsOpen(false)}>Okay</ModalButton>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Avatar;
