import React from "react";
import { ProgressBar, SIZE } from "baseui/progress-bar";
import * as Theme from "../../util/theme";
import { formatDate } from "../../util/datetime";
import Button from "../../elements/Button";
import LoaderBlock from "../loaders/LoaderBlock";
import { getFieldIndexes, getOverallProgress } from "../../util/form";

const isMobile = window.innerWidth <= 940;

const ProgressCard = ({ title, form, review, toForm, isAdmin, onOpenReview }) => {
  if (!form || !form._id) {
    return LoadingState;
  }

  // function getProgress(sectionIndex) {
  //   if (!form || form._id == null) return;

  //   let total = 0;
  //   let completed = 0;
  //   // for (let i = 0; i < form.data.sections.length; i++) {
  //   for (let j = 0; j < form.data.sections[sectionIndex].subsections.length; j++) {
  //     for (let k = 0; k < form.data.sections[sectionIndex].subsections[j].fields.length; k++) {
  //       total++;
  //       if (form.data.sections[sectionIndex].subsections[j].fields[k].value != null && form.data.sections[sectionIndex].subsections[j].fields[k].value != "") {
  //         completed++;
  //       }
  //     }
  //   }
  //   // }
  //   return ((completed / total) * 100).toFixed(0);
  // }

  function getProgress(sectionIndex) {
    // const { form: kyc } = this.state;
    // const kyc = form;

    if (!form || form._id == null) return;

    let total = 0;
    let completed = 0;
    for (let j = 0; j < form.data.sections[sectionIndex].subsections.length; j++) {
      for (let k = 0; k < form.data.sections[sectionIndex].subsections[j].fields.length; k++) {
        if (form.data.sections[sectionIndex].subsections[j].fields[k].conditionRef != null && form.data.sections[sectionIndex].subsections[j].fields[k].conditionRef != "") {
          const indexes = getFieldIndexes(form.data.sections[sectionIndex].subsections[j].fields[k].conditionRef, form);
          const conditionalField = form.data.sections[indexes[0]].subsections[indexes[1]].fields[indexes[2]];

          // if it has a condition, see if that condition is met
          if (conditionalField.value == form.data.sections[sectionIndex].subsections[j].fields[k].condition) {
            total++;
            if (form.data.sections[sectionIndex].subsections[j].fields[k].value != null && form.data.sections[sectionIndex].subsections[j].fields[k].value != "") {
              completed++;
              continue;
            }
          }

          // if the conditional field has a value then count it
          // if(conditionalField.value != null && conditionalField.value != "") {
          //   completed++;
          // }
          continue;
        }

        total++;
        if (form.data.sections[sectionIndex].subsections[j].fields[k].value != null && form.data.sections[sectionIndex].subsections[j].fields[k].value != "") {
          completed++;
        }
      }
    }
    return ((completed / total) * 100).toFixed(0);
  }

  // function getOverallProgress() {
  //   if (!form || form._id == null) return;

  //   let total = 0;
  //   let completed = 0;
  //   for (let i = 0; i < form.data.sections.length; i++) {
  //     for (let j = 0; j < form.data.sections[i]?.subsections?.length; j++) {
  //       for (let k = 0; k < form.data.sections[i]?.subsections[j]?.fields?.length; k++) {
  //         if (form.data.sections[i].subsections[j].fields[k].conditionRef != null && form.data.sections[i].subsections[j].fields[k].conditionRef != "") {
  //           const indexes = getFieldIndexes(form.data.sections[i].subsections[j].fields[k].conditionRef, form);
  //           const conditionalField = form.data.sections[indexes[0]].subsections[indexes[1]].fields[indexes[2]];
  
  //           // if it has a condition, see if that condition is met
  //           if (conditionalField.value == form.data.sections[i].subsections[j].fields[k].condition) {
  //             total++;
  //             if (form.data.sections[i].subsections[j].fields[k].value != null && form.data.sections[i].subsections[j].fields[k].value != "") {
  //               completed++;
  //               continue;
  //             }
  //           }
  
  //           // if the conditional field has a value then count it
  //           // if(conditionalField.value != null && conditionalField.value != "") {
  //           //   completed++;
  //           // }
  //           continue;
  //         }
  //         total++;
  //         if (form.data.sections[i]?.subsections[j]?.fields[k]?.value != null && form.data.sections[i]?.subsections[j]?.fields[k]?.value != "") {
  //           completed++;
  //         }
  //       }
  //     }
  //   }
  //   return ((completed / total) * 100).toFixed(0);
  // }

  function getSubtitleAndIcon() {
    if (Number(getOverallProgress()) < 95 && !review) return { title: "Your " + title + " is incomplete. Please finish all the questions", icon: alertsvg };
    if (!review) return { title: "Waiting for compliance to review your " + title, icon: warningsvg };
    if (review.status === "pending") return { title: "Compliance is reviewing your " + title, icon: warningsvg };
    if (review.status === "approved") return { title: "Approved on " + formatDate(review.created_at), icon: checksvg };
    if (review.status === "rejected") return { title: "Compliance rejected your " + title + " on " + formatDate(review.created_at), icon: alertsvg };
  }

  return (
    <div class='card mt-4 mb-4 p-8 pt-6 pb-2 max-w-none'>
      <div class='flex flex-row justify-between mb-8 pr-4'>
        <div className='flex flex-col'>
          <p class='label text-3xl' style={{ textAlign: "left", color: "black" }}>
            {title}
          </p>
          <p class='text-sm' style={{ color: "gray" }}>
            {getSubtitleAndIcon()?.title}
          </p>
        </div>
        <div class='flex flex-row'>
          {isAdmin && <Button text='Review' type='secondary' color='rgba(0,0,0,0.4)' onClick={onOpenReview} />}
          {getSubtitleAndIcon()?.icon}
        </div>
        {/* {isAdmin ? <Button text='Update' type='secondary' color='rgba(0,0,0,0.4)' onClick={onOpenReview} /> : getSubtitleAndIcon()?.icon} */}
      </div>

      {/* IS NOT MOBILE COMPONENT */}
      {!isMobile ? (
        <div class='flex flex-1 flex-col'>
          {form?.data?.sections.map((section, i) => {
            if (i % 2 === 0 || i === 0) {
              return (
                <div class='flex flex-row mb-4' key={i}>
                  <div class='flex-1 pr-4'>
                    <div class='flex flex-row justify-between pr-4'>
                      <p class='label'>{section.title}</p>
                      <p class='label'>{getProgress(i)}%</p>
                    </div>
                    <ProgressBar value={getProgress(i)} size={SIZE.large} overrides={progressBarOverride} />
                  </div>
                  <div class='flex-1'>
                    {form.data.sections[i + 1] != null && (
                      <>
                        <div class='flex flex-row justify-between pr-4'>
                          <p class='label'>{form.data.sections[i + 1]?.title}</p>
                          <p class='label'>{getProgress(i + 1)}%</p>
                        </div>
                        <ProgressBar value={getProgress(i + 1)} size={SIZE.large} overrides={progressBarOverride} />
                      </>
                    )}
                  </div>
                </div>
              );
            }
          })}
          <div class='flex flex-row mb-4 pr-2 pt-6 mt-2 justify-between' style={{ borderTop: "4px solid rgb(220,220,220)" }}>
            <div class='flex flex-1 flex-col mr-8' style={{ maxWidth: "50%" }}>
              <div class='flex flex-row justify-between pr-4'>
                <p class='label'>Overall Progress</p>
                <p class='label'>{getOverallProgress(form)}%</p>
              </div>
              <ProgressBar value={getOverallProgress(form)} size={SIZE.large} overrides={progressBarOverride} />
            </div>

            <>
              <Button type='tertiary' text={"Update " + title} onClick={toForm} />
            </>
          </div>
        </div>
      ) : (
        <div class='flex flex-1 flex-col'>
          {/* IS MOBILE COMPONENT */}
          {form?.data?.sections.map((section, i) => {
            return (
              <div class='flex flex-row mb-4' key={i}>
                <div class='flex-1 pr-4'>
                  <div class='flex flex-row justify-between pr-4'>
                    <p class='label'>{section.title}</p>
                    <p class='label'>{getProgress(i)}%</p>
                  </div>
                  <ProgressBar value={getProgress(i)} size={SIZE.large} overrides={progressBarOverride} />
                </div>
              </div>
            );
          })}
          {/* {form.data.sections.length % 2 === 0 && ( */}
          <div class='flex flex-row mb-4 pr-2 pt-6 mt-2 justify-between' style={{ borderTop: "4px solid rgb(220,220,220)" }}>
            <div class='flex flex-1 flex-col mr-8' style={{ maxWidth: "50%" }}>
              <div class='flex flex-row justify-between pr-4'>
                <p class='label'>Overall Progress</p>
                <p class='label'>{getOverallProgress(form)}%</p>
              </div>
              <ProgressBar value={getOverallProgress(form)} size={SIZE.large} overrides={progressBarOverride} />
            </div>

            <>
              <Button type='tertiary' text={"Update " + title} onClick={toForm} />
            </>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgressCard;

const progressBarOverride = {
  BarContainer: {
    style: ({}) => {
      return {
        marginLeft: 0,
      };
    },
  },
  BarProgress: {
    style: ({}) => {
      return {
        backgroundColor: Theme.SECONDARY,
      };
    },
  },
};

const checksvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 24 24'
    fill='none'
    stroke='#43a047'
    stroke-width='2'
    stroke-linecap='round'
    stroke-linejoin='round'
    class='feather feather-check-circle'
  >
    <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path>
    <polyline points='22 4 12 14.01 9 11.01'></polyline>
  </svg>
);

const alertsvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 24 24'
    fill='none'
    stroke='#f50057'
    stroke-width='2'
    stroke-linecap='round'
    stroke-linejoin='round'
    class='feather feather-alert-circle'
  >
    <circle cx='12' cy='12' r='10'></circle>
    <line x1='12' y1='8' x2='12' y2='12'></line>
    <line x1='12' y1='16' x2='12.01' y2='16'></line>
  </svg>
);

const warningsvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 24 24'
    fill='none'
    stroke='#c7a500'
    stroke-width='2'
    stroke-linecap='round'
    stroke-linejoin='round'
    class='feather feather-alert-triangle'
  >
    <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
    <line x1='12' y1='9' x2='12' y2='13'></line>
    <line x1='12' y1='17' x2='12.01' y2='17'></line>
  </svg>
);

const LoadingState = (
  <div class='card mt-4 mb-4 p-8 pt-6 pb-2 max-w-none'>
    <div class='flex flex-row justify-between mb-8 pr-4'>
      <div className='flex flex-col'>
        <LoaderBlock height={32} width={200} marginBottom={8} />
        <LoaderBlock height={12} width={120} marginBottom={8} />
      </div>
      <div class='flex flex-row'>
        <LoaderBlock height={32} width={32} marginBottom={8} />
      </div>
      {/* {isAdmin ? <Button text='Update' type='secondary' color='rgba(0,0,0,0.4)' onClick={onOpenReview} /> : getSubtitleAndIcon()?.icon} */}
    </div>

    <div class='flex flex-1 flex-col'>
      {[0, 0, 0, 0].map((section, i) => {
        if (i % 2 === 0 || i === 0) {
          return (
            <div class='flex flex-row mb-4'>
              <div class='flex-1 pr-4'>
                <div class='flex flex-row justify-between pr-4'>
                  <LoaderBlock height={12} width={32} marginBottom={8} />
                  <LoaderBlock height={12} width={80} marginBottom={8} />
                </div>
                <LoaderBlock height={16} width={200} marginBottom={8} />
              </div>
              <div class='invisible md:visible flex-1 '>
                <>
                  <div class='flex flex-row justify-between pr-4'>
                    <LoaderBlock height={12} width={32} marginBottom={8} />
                    <LoaderBlock height={12} width={80} marginBottom={8} />
                  </div>
                  <LoaderBlock height={16} width={200} marginBottom={8} />
                </>
              </div>
            </div>
          );
        }
      })}
      <div class='flex flex-row mb-4 pr-2 pt-6 mt-2 justify-between' style={{ borderTop: "4px solid rgb(220,220,220)" }}>
        <div class='flex flex-1 flex-col mr-8' style={{ maxWidth: "50%" }}>
          <div class='flex flex-row justify-between pr-4'>
            <LoaderBlock height={12} width={32} marginBottom={8} />
            <LoaderBlock height={12} width={80} marginBottom={8} />
          </div>
          <LoaderBlock height={16} width={200} marginBottom={8} />
        </div>
      </div>
    </div>
  </div>
);
