import React, { useState, useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal"
import { KIND as ButtonKind, KIND } from "baseui/button"
import { Input } from 'baseui/input';

const AddUserToClientModal = ({ client, isOpen, onSend, onClose }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)

  async function submit() {
    if(name.length === 0) return setError("Must provide a name");
    const isValid = isValidEmail()
    if(!isValid) return;

    await onSend({ name, email: email.trim(), client_id: client._id, company_id: client.company_id })
  }
  
  function isValidEmail() {
    const isValid = email.includes('@') && !email.includes(',')
    if(!isValid) setError('Invalid email')
    // if(isValid) setError('')
    return isValid;
  }

  useEffect(() => {
    
  }, [email])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>Add User</ModalHeader>
      <ModalBody>
        <div class="flex flex-col">
          <div>
            <p>Add a user to have access to {client.name}</p>

            <div className="mt-4">
              <label>Name</label>
              <Input placeholder="Name" onChange={e => setName(e.target.value)} value={name} />
            </div>
            <div className="mt-4">
              <label>Email</label>
              <Input placeholder="Email" onChange={e => setEmail(e.target.value)} value={email} />
            </div>
            {/* <Select
              options={options}
              onChange={({ value }) => setStatus(value)}
              value={status}
            /> */}
          </div>
          <p style={{color: 'red'}}>{error}</p>
          {/* <div class='mt-4'>
            <p>Enter Subscription Amount</p>
            <Input startEnhancer='$' onChange={(e) => setAmount(e.target.value)} value={amount} />
          </div> */}
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={onClose} kind={KIND.secondary}>
          Close
        </ModalButton>
        <ModalButton onClick={submit}>Send</ModalButton>
      </ModalFooter>
    </Modal>
  )
}

export default AddUserToClientModal
