import { useState } from "react";
import { resendInvite } from "../api/client";
import Button from "./Button";

const AccountNotActive = ({ client }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [wasEmailSent, setWasEmailSent] = useState(false);

  async function _resendInvite() {
    setIsLoading(true);
    try {
      await resendInvite(client._id);
      setIsLoading(false);
      setWasEmailSent(true);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  }

  return (
    <div class='card mt-8 flex-row justify-between items-center'>
      <div class='flex-col'>
        <p class='text-xl' style={{ color: "black" }}>
          {client.name}'s account is not active yet.
        </p>
        <p class='text-lg'>Would you like to send them a reminder to setup their account?</p>
      </div>

      {!wasEmailSent && <Button text='Resend Invite' type='tertiary' isLoading={isLoading} onClick={_resendInvite} />}
      {wasEmailSent && (
        <div class='flex flex-row items-center mr-4'>
          <p class='mr-2' style={{opacity: 0.5}}>Invite Resent!</p>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='32'
            height='32'
            viewBox='0 0 24 24'
            fill='none'
            stroke='#43a047'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            class='feather feather-check-circle'
          >
            <path d='M22 11.08V12a10 10 0 1 1-5.93-9.14'></path>
            <polyline points='22 4 12 14.01 9 11.01'></polyline>
          </svg>
        </div>
      )}
    </div>
  );
};

export default AccountNotActive;
