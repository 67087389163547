import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal";
import { KIND as ButtonKind, KIND } from "baseui/button";
import { Checkbox } from "baseui/checkbox";
import { Select } from "baseui/select";
import { Input } from "baseui/input";
import { me as myBankAccounts } from "../../api/bank-account";
import CurrencyInput from "react-currency-input-field";

const SubscribeModal = ({ mySubscription, plan, bankAccounts, isOpen, onSubscribe, onClose }) => {
  // const [account, setAccount] = useState([]);
  const [account, setAccount] = useState(
    mySubscription != null
      ? [
          {
            id: mySubscription?.bank_account?._id,
            label:
              mySubscription?.bank_account?.bank +
              " (..." +
              mySubscription?.bank_account?.iban.substring(mySubscription?.bank_account?.iban?.length - 4, mySubscription?.bank_account?.iban?.length) +
              ")",
          },
        ]
      : []
  );
  const [amount, setAmount] = useState(mySubscription?.amount || "");
  const [checked, setChecked] = useState(mySubscription?._id != null);

  function _onSubscribe() {
    console.log(account);
    if (account.length == 0) return;

    onSubscribe(amount, account[0].id);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>{plan.name}</ModalHeader>
      <ModalBody>
        <div class='flex flex-col'>
          <div>
            <p>Select Bank Account</p>
            <Select
              options={bankAccounts.map((b) => ({
                id: b._id,
                label: b.bank + " (..." + b.iban.substring(b.iban.length - 4, b.iban.length) + ")",
              }))}
              onChange={({ value }) => setAccount(value)}
              value={account}
            />
          </div>
          <div class='mt-4'>
            <p>Enter Subscription Amount</p>
            {/* <Input startEnhancer='$' onChange={(e) => setAmount(e.target.value)} value={amount} mas /> */}
            <CurrencyInput
              style={{ height: 48, paddingLeft: 12, fontSize: 16, boxShadow: "none", width: "100%", backgroundColor: 'rgb(238,238,238)' }}
              placeholder='SAR 1,000,000'
              prefix='SAR '
              decimalsLimit={0}
              value={amount}
              onValueChange={(value) => setAmount(value)}
            />
          </div>
          <div class='mt-4'>
            <p>
              Agree to the terms and conditions. Click{" "}
              <a
                rel='noreferrer'
                style={{ color: "blue", borderBottom: "2px solid" }}
                href='https://jeffery-saudi.s3.eu-central-1.amazonaws.com/IMPACT_3.0_Fund_-_T%26Cs.pdf'
                target='_blank'
              >
                here
              </a>{" "}
              to view the agreement
            </p>
            <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={onClose} kind={KIND.secondary}>
          Close
        </ModalButton>
        <ModalButton disabled={account.length == 0 || amount == null || !checked} onClick={_onSubscribe}>
          {mySubscription ? "Edit" : "Subscribe"}
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};

export default SubscribeModal;
