import React, { Component } from "react"
import PropTypes from "prop-types"

// import json from "../fields/inv_acc_ope_for.json";
// import "tailwindcss/tailwind.css"
import { Input, MaskedInput } from "baseui/input"
import { PhoneInput, COUNTRIES } from "baseui/phone-input"
import Select from "../inputs/Select"
import DatePicker from "../inputs/DatePicker"
import RadioButton from "../inputs/RadioButton"
import FileInput from "../inputs/FileInput"
import List from "../inputs/List"
import { CountryActivityTable, PoliticalPersonsTable, ManagerExecutiveTable, OwnershipStructureTable, UltimateOwnerTable, AuthorizedSignatoriesTable, DirectorsTable } from "../inputs/Tables"
import { upload } from "../api/aws"
import * as Theme from "../util/theme"
import CurrencyInput from "react-currency-input-field"
import { isNullOrEmpty } from "../util/form"

const isMobile = window.innerWidth < 960

class SubSection extends Component {
  static propTypes = {
    subsection: PropTypes.objectOf({
      title: PropTypes.string,
      fields: PropTypes.array,
    }),
  }

  constructor(props) {
    super(props)

    this.state = {
      //   index: 0,
      isIncomplete: false,
    }
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  onUploadFile = async (fieldTitle, file) => {
    const { client } = this.props
    console.log("file", file)

    try {
      const formData = new FormData()
      formData.append("file", file)
      formData.append("client_id", client._id)

      const data = await upload(formData)
      console.log(data)

      this.props.onInputField(fieldTitle, data.data)
    } catch (e) {
      console.log(e)
    }
  }

  getInputType = (field) => {
    if (!field) return <></>
    const { onInputField, hasIncompleteFields } = this.props

    switch (field.type) {
      case "text":
      case "email":
        return (
          <Input
            key={field.id}
            placeholder={field.title}
            value={field.value}
            onChange={(e) => onInputField(field.id, e.target.value)}
            error={hasIncompleteFields && isNullOrEmpty(field.value)}
          />
        )

      case "percentage":
        return (
          <Input
            key={field.id}
            placeholder={field.title}
            value={field.value}
            type="number"
            maxLength={3}
            onChange={(e) => onInputField(field.id, e.target.value)}
            endEnhancer="%"
            error={hasIncompleteFields && isNullOrEmpty(field.value)}
          />
        )

      case "number":
        return (
          <Input
            type="number"
            key={field.id}
            placeholder={field.title}
            value={field.value}
            onChange={(e) => onInputField(field.id, e.target.value)}
            error={hasIncompleteFields && isNullOrEmpty(field.value)}
          />
        )

      case "zip":
        return (
          <Input
            type="number"
            key={field.id}
            placeholder={field.title}
            value={field.value}
            maxLength={5}
            onChange={(e) => onInputField(field.id, e.target.value)}
            error={hasIncompleteFields && isNullOrEmpty(field.value)}
          />
        )

      case "phone":
        return (
          <PhoneInput
            country={COUNTRIES[field.country]}
            key={field.id}
            text={field.value}
            onTextChange={(e) => onInputField(field.id, e.target.value)}
            onCountryChange={({ option }) => onInputField(field.id, option.id, "phone")}
            error={hasIncompleteFields && isNullOrEmpty(field.value)}
          />
        )

      case "iban":
        return (
          <MaskedInput
            type="text"
            key={field.id}
            placeholder={field.title}
            value={field.value}
            onChange={(e) => onInputField(field.id, e.target.value)}
            mask="SA99 9999 9999 9999 9999 9999"
            error={hasIncompleteFields && isNullOrEmpty(field.value)}
          />
        )

      case "money":
        return (
          <CurrencyInput
            style={{ height: 48, paddingLeft: 12, fontSize: 16, boxShadow: "none", width: "100%", backgroundColor: "rgb(238,238,238)", borderColor: hasIncompleteFields && isNullOrEmpty(field.value) ? 'red' : 'none' }}
            placeholder="SAR 1,000,000"
            prefix="SAR "
            decimalsLimit={0}
            value={field.value}
            onValueChange={(value) => onInputField(field.id, value)}
          />
        )

      case "dropdown":
        return (
          <Select
            key={field.id + field.value}
            multi={field.multi}
            value={field.value}
            placeholder={field.title}
            options={field.options}
            onSelect={(value) => onInputField(field.id, value)}
            error={hasIncompleteFields && isNullOrEmpty(field.value)}
          />
        )
      // return <Select key={field.id} multi={field.multi} value={field.value} placeholder={field.id} options={field.options} onSelect={(value) => (field.value = value)} />;
      case "radio":
        return <RadioButton key={field.id + field.value} value={field.value} options={field.options} onSelect={(value) => onInputField(field.id, value)} />
      case "date":
        let date = new Date()
        let maxDate
        let minDate
        if (field.cantBe == "after") {
          maxDate = new Date()
        }
        if (field.cantBe == "before") {
          minDate = new Date()
        }

        if (Date.parse(field.value)) {
          date = new Date(field.value)
        }

        return <DatePicker key={field.id} value={date} onSelect={(value) => onInputField(field.id, value)} minDate={minDate} maxDate={maxDate} />
      case "signature":
        return (
          <input
            key={field.id}
            value={field.value}
            onChange={(e) => onInputField(field.id, e.target.value)}
            placeholder="Signature"
            style={{ fontWeight: "bold", width: "80%", height: 64, backgroundColor: "rgb(240,240,240)", fontFamily: "cursive", fontSize: "1.5rem", paddingLeft: 12, boxShadow: "none" }}
            error={hasIncompleteFields && isNullOrEmpty(field.value)}
          />
        ) //<Input $style={{fontFamily: 'cursive', color:'blue'}} placeholder={field.id} value={field.value} onChange={(e) => onInputField(field.id, e.target.value)} />;

      case "uploadimage":
        console.log("IMAGE FIELD", field.value)
        return <FileInput key={field.id} onSelect={(file) => this.onUploadFile(field.id, file)} value={field.value} />

      case "list":
        return <List key={field.id} value={field.value} onChange={(value) => onInputField(field.id, value)} />

      case "table_countryactivity":
        return <CountryActivityTable key={field.id} value={field.value} onChange={(value) => onInputField(field.id, value)} />

      case "table_politicalpersons":
        return <PoliticalPersonsTable key={field.id} value={field.value} onChange={(value) => onInputField(field.id, value)} />

      case "table_infoManagersExecs":
        return <ManagerExecutiveTable key={field.id} value={field.value} onChange={(value) => onInputField(field.id, value)} />

      case "table_ownershipstruct":
        return <OwnershipStructureTable key={field.id} value={field.value} onChange={(value) => onInputField(field.id, value)} />

      case "table_ultowner":
        return <UltimateOwnerTable key={field.id} value={field.value} onChange={(value) => onInputField(field.id, value)} />

      case "table_authsigs":
        return <AuthorizedSignatoriesTable key={field.id} value={field.value} onChange={(value) => onInputField(field.id, value)} />

      case "table_directors":
        return <DirectorsTable key={field.id} value={field.value} clientId={this.props.client._id} onChange={(value) => onInputField(field.id, value)} />

      default:
        return <Input key={field.id} placeholder={field.title} value={field.value} onChange={(e) => onInputField(field.id, e.target.value)} error={hasIncompleteFields && isNullOrEmpty(field.value)} />
    }
  }

  render() {
    const { subsection, client, canFieldBeRendered } = this.props
    return (
      <div class="flex-initial">
        <h1>{subsection?.title}</h1>

        {subsection.fields.map((field, i) => {
          if (client?.type === "personal" && field.only === "corp") return <></>
          if (field.conditionRef) {
            const canBeRendered = canFieldBeRendered(field.conditionRef, field.condition)
            if (!canBeRendered) return <></>
          }

          if (!field.group || isMobile) {
            return (
              <div class="mb-4 " key={i}>
                <p class="mb-2 label" style={{ color: "rgb(140,140,140)" }}>
                  {field.title}
                </p>
                {/* <Input placeholder={field.title} /> */}
                {this.getInputType(field)}
              </div>
            )
          }

          // catch the second one in the group
          if (subsection.fields[i - 1]?.group == field.group) return

          if (field.group) {
            return (
              <div class="flex flex-row justify-between flex-1 items-stretch mb-4 p-2">
                <div class="flex flex-col flex-1 mr-8">
                  <p class="mb-2 label" style={{ color: "rgb(140,140,140)" }}>
                    {field.title}
                  </p>
                  {this.getInputType(field)}
                </div>
                <div class="flex flex-col flex-1 mr-4">
                  <p class="mb-2 label" style={{ color: "rgb(140,140,140)" }}>
                    {subsection.fields[i + 1]?.title}
                  </p>
                  {this.getInputType(subsection.fields[i + 1])}
                </div>
              </div>
            )
          }
          return <></>
        })}
      </div>
    )
  }
}

export default SubSection
