import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import Credentials from "./tabs/Credentials"
import Finished from "./tabs/Finished"

// import { getInvite, update, updatePassword, updateOnboard, finishOnboard, updateInvite } from "../../api/client"
import { getInvite, updatePassword, updateInvite } from "../../api/user"
import * as Theme from "../../util/theme"
import logo from "../../data/images/i46logo.png"


class OnboardPasswordOnly extends Component {
  constructor(props) {
    super(props)

    this.state = {
      index: 0,
      isAgreementChecked: false,
      invite: {},
      error: null,
      isSubmit: false,
      client: {
        name: "",
        email: "",
        password: "",
        type: "individual",
        agreement: {
          isChecked: false,
        },
        type: "",
        credentials: {
          email: "",
          password: "",
          password2: "",
        },
        nationalAddress: {
          g_na_country: "Saudi Arabia",
          g_na_city: "Riyadhd",
          g_na_area: "",
          g_na_street: "",
          g_na_zip: "",
          g_na_po: "",
          g_na_phone: "",
          g_na_fax: "",
          g_na_email: "",
        },
        personalInformation: {},
        financialInformation: {},
      },
      canNavigate: false,
    }
  }

  async componentDidMount() {
    await this.getClient()
  }

  componentDidUpdate(prevProps, prevState) {
    // Initial render
    // if (!prevState.client._id && this.state.client._id) return
  }

  async getClient() {
    const { inviteId } = this.props
    try {
      const { data } = await getInvite(inviteId)
      this.setState({ client: data.user, invite: data.invite })
      console.log(data.user)
    } catch (e) {
      console.log(e)
      this.setState({
        error: {
          msg: "Invalid invite, please conteact Impact 46 for your correct login link.",
        },
      })
    }
  }

  //   onCredentialsUpdated = async () => {
  //     const { _id: clientId, password } = this.state.client
  //     try {
  //       await updatePassword(clientId, password)
  //     } catch (e) {
  //       console.log(e)
  //       this.setState({
  //         msg: "Error updating account.",
  //       })
  //     }
  //   }

  onSubmit = async () => {
    const { _id: userId } = this.state.client

    if(this.state.isSubmit) {
        return this.props.history.push('/login')
    }

    try {
      await updateInvite(this.props.inviteId, "complete")
      await updatePassword(userId, this.state.client.password)
      this.setState({ isSubmit: true })
    } catch (e) {
      console.log(e)
    }
  }

  //   onUpdate = async (type) => {
  //     try {
  //       const { client } = this.state

  //       await updateOnboard(type, client[type], this.state.client._id)
  //     } catch (e) {
  //       console.log(e)
  //     }
  //   }

  //   async onFinishOnboard() {
  //     try {
  //       const invite = await updateInvite(this.props.inviteId, "complete")
  //       await finishOnboard(this.state.client._id)
  //       console.log(invite)
  //       // this.props.history.push("/login");
  //     } catch (e) {
  //       console.log(e)
  //     }
  //   }

  render() {
    const { client, index, isSubmit, canNavigate, error } = this.state
    if(client.email === '') return <></>
    return (
      <div class="min-h-screen pl-8 pr-8 lg:pl-64 lg:pr-64" style={{ paddingTop: "5vh", backgroundColor: Theme.PRIMARY }}>
        {/* <ProgressBar index={index} key={Date.now()} /> */}

        <div style={{ position: "absolute", left: 16, top: 16 }}>
          <img src={logo} width="100" />
        </div>
        <div class="flex-initial md:p-8 mb-16 mt-16 ">
          <div id="onboard-header" class="flex center flex-row justify-between items-center">
            <div class="flex flex-col">
              <p className="text-lg lg:text-5xl">Jeffery</p>
              <p style={{ fontSize: "16px", opacity: 0.5, marginLeft: 4 }}>Please create your password</p>
            </div>
            {/* <p className="text-sm md:text-lg">
              {index + 1}/{steps.length}
            </p> */}
          </div>

          <div class="card max-w-none">
            {!isSubmit && <Credentials client={client} toggleNavigation={(canNavigate) => this.setState({ canNavigate })} onUpdate={(password) => this.setState({ client: { ...client, password } })} />}
            {isSubmit && <Finished toggleNavigation={() => this.setState({ canNavigate: true })}/>}
            {error && (
              <div class="ml-4">
                <p style={{ color: "red" }}>{error.msg}</p>
              </div>
            )}
            <div class="justify-around flex flex-row flex-1">
              <div onClick={this.onSubmit} class="card flex flex-1 mt-4 ml-4" style={{ backgroundColor: Theme.PRIMARY, cursor: "pointer" }}>
                <button onClick={this.onSubmit} class={"center center-vertical font-bold text-xl"} style={{ color: "white", opacity: !canNavigate ? 0.5 : 1 }}>
                  Submit
                  {/* {client.type === "individual" ? (index === 6 ? "Login" : "Next") : index === 3 ? "Login" : "Next"} */}
                  {/* {index === 6 && client.type == 'individual' ? "Login" : "Next"} */}
                  {/* {(index === 3 && client.type == 'corporation') ? "Login" : ""} */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(OnboardPasswordOnly)
