import React, { Component } from "react";
import Login from "../components/Login";
import { authenticate } from "../api/auth";
import { me as mySubscription } from "../api/subscription-plan";
import { me as myBankAccount } from "../api/bank-account";

const Context = React.createContext();

export default class Provider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isReady: false,
      // clients: clients,
      activeClient: {},
      user: {},
      clients: [],
      mySubscription: null,
      myBankAccounts: null,
    };
  }

  async componentDidMount() {
    await this.init();
  }

  async init() {
    try {
      let _user = localStorage.getItem("user");
      if (!_user) {
        return this.setState({ isAuthenticated: false, isReady: true });
      }

      _user = JSON.parse(_user);
      const { data: user } = await authenticate({ user_id: _user._id });

      localStorage.setItem("user", JSON.stringify(user));
      let activeClient = {}
      // if (user.is_client) activeClient = user;

      await this.setState({ user, activeClient, isAuthenticated: true, isReady: true });
      await this.getBankAccounts();
      await this.getMySubscription();

      console.log(user);
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ isReady: true });
    }
  }

  // Call on login
  async setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
    this.setState({ user, isAuthenticated: true, isReady: true });
  }

  async setActiveClient(client) {
    if(!client) client = {}
    // const client = this.state.clients.find(c => c.id == clientId)
    await this.setState({ activeClient: client });
    await this.getMySubscription();
    await this.getBankAccounts();
  }

  async setClients(clients) {
    await this.setState({ clients })
  }

  async getMySubscription() {
    const { activeClient } = this.state;

    try {
      const { data: sub } = await mySubscription.get(activeClient._id);

      if (!sub) return;

      // const { data: subscriptionPlan } = await getSubscriptionPlan(subscription.plan_id);
      await this.setState({ mySubscription: sub });
      // this.setState({ subscription, subscriptionPlan });
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  async getBankAccounts() {
    const { activeClient } = this.state;
    console.log('client:', activeClient)
    try {
      const { data: myBankAccounts } = await myBankAccount.get(activeClient._id);
      console.log(myBankAccounts);
      if (!myBankAccounts || myBankAccounts.length == 0) return;

      await this.setState({ myBankAccounts });
    } catch (e) {
      console.log(e);
    } finally {
      // await this.setState({ loading: false })
      /**
       * Add indicator to tab
       */
    }
  }

  render() {
    const value = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      activeClient: this.state.activeClient,
      mySubscription: this.state.mySubscription,
      myBankAccounts: this.state.myBankAccounts,
      clients: this.state.clients,

      setUser: (user) => this.setUser(user),
      setActiveClient: (client) => this.setActiveClient(client),
      setClients: (clients) => this.setClients(clients)
    };

    /**
     * By setting this isReady state var, we can ensure that we check if the user
     * is authenticated BEFORE any components are rendered.
     * In other words, we only render components AFTER we check if the user is authenticated
     */
    return <Context.Provider value={value}>{this.state.isReady ? this.props.children : null}</Context.Provider>;
  }
}

export const Consumer = Context.Consumer;

export function withContext(Component) {
  return function UserManager(props) {
    return <Context.Consumer>{(context) => <Component {...props} context={context} />}</Context.Consumer>;
  };
}
